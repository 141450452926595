import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { FormFieldBase } from '../models';

@Injectable()
export class DynamicFormFieldService {
  toFormGroup<T>(
    fields: Array<FormFieldBase<T>>,
    formValidator?: ValidatorFn[],
  ): UntypedFormGroup {
    const group = {};

    fields.forEach((field) => {
      group[field.key] = new UntypedFormControl(
        {
          value: field.value,
          disabled: field.disabled,
        },
        field.validators,
      );
    });

    const formGroup = new UntypedFormGroup(group, formValidator);

    fields.forEach((field) => {
      if (field.dynamicOptions) {
        formGroup.controls[field.dynamicOptions.formValue].valueChanges.subscribe(
          (val) => {
            field.options = field.dynamicOptions?.function(val) || [];
          },
        );
      }
    });

    return formGroup;
  }

  fillForm(formGroup: UntypedFormGroup, fields) {
    for (const field of Object.keys(fields)) {
      formGroup.controls[field].setValue(fields[field]);
    }
  }

  enableFormControls<T>(formGroup: UntypedFormGroup, fields: Array<FormFieldBase<T>>) {
    fields.map((field) => {
      formGroup.controls[field.key].enable();
    });
  }

  disableFormControls<T>(formGroup: UntypedFormGroup, fields: Array<FormFieldBase<T>>) {
    fields.map((field) => {
      formGroup.controls[field.key].disable();
    });
  }
}
