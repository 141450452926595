import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { Simulation } from '../../models';

@Injectable()
export class SimulationService {
  constructor(private dataService: DataService) {}

  createSimulation(payload: any) {
    return this.dataService
      .post(`prospecting/simulations`, payload)
      .pipe(map((res) => res.data));
  }

  updateSimulation(uuid: string, payload: any) {
    return this.dataService
      .patch<Simulation>(`prospecting/simulations/${uuid}`, payload)
      .pipe(map((res) => res.data));
  }

  calculateSimulation(payload: { simulation: string }) {
    return this.dataService
      .post<Simulation>(`prospecting/simulations/calculate`, payload)
      .pipe(map((res) => res.data));
  }

  calculateNotarialFees(payload: {
    projectCode: string;
    productCode: string;
    propertyValue: number;
    parkingLot: number;
    store: number;
    totalDownPayment: number;
    modality: number;
  }) {
    return this.dataService
      .post(`prospecting/simulations/notarial-fees/calculate`, payload)
      .pipe(map((res) => res.data));
  }
}
