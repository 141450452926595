import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { CustomerService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  isLoading: boolean;
  isSpouseLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

const startSpouseLoader: Partial<StateModel> = { isSpouseLoading: true };
const stopSpouseLoader: Partial<StateModel> = { isSpouseLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'customers',
})
@Injectable()
export class CustomerState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  @Computed()
  get isSpouseLoading() {
    return this.snapshot.isSpouseLoading;
  }

  constructor(private customerService: CustomerService) {
    super();
  }

  updateCustomer(uuid: string, customer: any, isSpouse: boolean = false) {
    if (isSpouse) {
      this.patchState(startSpouseLoader);
    } else {
      this.patchState(startLoader);
    }

    return this.customerService.updateCustomer(uuid, customer).pipe(
      finalize(() => {
        if (isSpouse) {
          this.patchState(stopSpouseLoader);
        } else {
          this.patchState(stopLoader);
        }
      }),
    );
  }

  addEconomicActivity(customerUuid: string, activity: any) {
    // this.patchState(startLoader);

    return this.customerService
      .addEconomicActivity({
        customerUuid,
        ...activity,
      })
      .pipe(
        finalize(() => {
          // this.patchState(stopLoader);
        }),
      );
  }

  updateEconomicActivity(economicActivityUuid: string, activity: any) {
    return this.customerService
      .updateEconomicActivity(economicActivityUuid, activity)
      .pipe(
        finalize(() => {
          // this.patchState(stopLoader);
        }),
      );
  }

  getEconomicActivities(customerUuid: string) {
    // this.patchState(startLoader);

    return this.customerService.getEconomicActivities(customerUuid).pipe(
      finalize(() => {
        // this.patchState(stopLoader);
      }),
    );
  }

  removeEconomicActivity(uuid: string) {
    // this.patchState(startLoader);

    return this.customerService.removeEconomicActivity(uuid).pipe(
      finalize(() => {
        // this.patchState(stopLoader);
      }),
    );
  }
}
