import {
  ButtonComponent,
  ButtonIconDirective,
  ButtonTextIconDirective,
} from './button/button.component';
import { HeaderComponent } from './header/header.component';
import { CardComponent } from './card/card.component';
import { SwitchComponent, SwitchDisableDirective } from './switch/switch.component';
import {
  ChipsComponent,
  ChipsDisableDirective,
  ChipsMultipleDirective,
} from './chips/chips.component';
import { ListComponent, ListIconDirective } from './list/list.component';
import { ChipComponent } from './chip/chip.component';
import { SelectComponent } from './select/select.component';
import { BadgeComponent } from './badge/badge.component';
import { TextfieldComponent } from './textfield/textfield.component';
import { IconComponent } from './icon/icon.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ModalComponent } from './modal/modal.component';

import { TextviewComponent } from './textview/textview.component';
import { FileComponent } from './file/file.component';
import { UploadFile } from './upload-file/upload-file';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';

export const UI_COMPONENTS = [
  HeaderComponent,
  ButtonComponent,
  ButtonIconDirective,
  CardComponent,
  ButtonTextIconDirective,
  SwitchComponent,
  SwitchDisableDirective,
  ChipComponent,
  ChipsComponent,
  ChipsMultipleDirective,
  ChipsDisableDirective,
  ListComponent,
  ListIconDirective,
  SelectComponent,
  BadgeComponent,
  TextfieldComponent,
  IconComponent,
  PaginatorComponent,
  ModalComponent,
  TextviewComponent,
  FileComponent,
  UploadFile,
  SkeletonLoaderComponent,
];

export * from './list/types';
export * from './chips/types';
export * from './select/types';
export * from './icon/types';
export * from './modal/types';

export * from './header/header.component';
export * from './button/button.component';
export * from './card/card.component';
export * from './switch/switch.component';
export * from './chips/chips.component';
export * from './list/list.component';
export * from './chip/chip.component';
export * from './select/select.component';
export * from './badge/badge.component';
export * from './textfield/textfield.component';
export * from './icon/icon.component';
export * from './paginator/paginator.component';
export * from './modal/modal.component';
export * from './textview/textview.component';
export * from './skeleton-loader/skeleton-loader.component';
