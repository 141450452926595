import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { S3PreSignedModel } from '../../models';

@Injectable()
export class ApplicationFileService {
  constructor(private dataService: DataService) {}

  uploadPreSignedUrl(applicationUuid: string, fileName: string, fileType: string) {
    return this.dataService
      .post<S3PreSignedModel>(`application/files/upload-pre-signed-url`, {
        applicationUuid,
        fileName,
        fileType,
      })
      .pipe(map((res) => res.data));
  }

  save(applicationUuid: string, s3ObjectKey: string) {
    return this.dataService
      .post(`application/files/save`, { applicationUuid, s3ObjectKey })
      .pipe(map((res) => res.data));
  }
}
