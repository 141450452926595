import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'vv-modal',
  templateUrl: 'modal.component.html',
  animations: [
    trigger('modalAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalComponent extends BaseComponent {
  @Input() size: string;
  @Input() show: boolean;
  @Input() closeButtonByDefault: boolean;
  @Output() showChange = new EventEmitter<boolean>();
  @Input() showCloseButton = true;

  close() {
    this.show = false;
    this.showChange.emit(this.show);
  }
}
