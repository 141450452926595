import { Injectable } from '@angular/core';
import { departaments, districts, provinces } from '..';

@Injectable({ providedIn: 'root' })
export class PlacesService {
  getDepartamentOptions() {
    const departamentOptions = departaments.map((departament) => {
      return { value: departament.id, label: departament.name };
    });

    return departamentOptions;
  }

  getProvinceOptionsByDepartament(departamentId: string) {
    const provinceOptions = provinces
      .filter((province) => province.department_id == departamentId)
      .map((province) => {
        return { value: province.id, label: province.name };
      });

    return provinceOptions;
  }

  getDistrictOptionsByProvince(provinceId: string) {
    const districtOptions = districts
      .filter((district) => district.province_id == provinceId)
      .map((district) => {
        return { value: district.id, label: district.name };
      });

    return districtOptions;
  }
}
