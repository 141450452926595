import { maritalStatuses, MARITAL_STATUS_MARRIED } from '@vivela/xplat/api';
import {
  PrequalificationReportExternalVariables,
  PrequalificationReportLevelOneVariables,
  PrequalificationReportMandatoryVariables,
  PrequalificationReportPerson,
} from './';
import { Expose } from 'class-transformer';

export class PrequalificationReport {
  @Expose()
  uuid: string;

  @Expose()
  result: string;

  @Expose()
  persons: PrequalificationReportPerson[];

  @Expose()
  mandatoryVariables: PrequalificationReportMandatoryVariables;

  @Expose()
  levelOneVariables: PrequalificationReportLevelOneVariables;

  @Expose()
  externalVariables: PrequalificationReportExternalVariables;

  @Expose()
  hasSecondaryBorrower() {
    const principalBorrower = this.persons.find(
      (person) => person.principalBorrower == true,
    );

    return principalBorrower
      ? principalBorrower.maritalStatus == maritalStatuses[MARITAL_STATUS_MARRIED]
      : false;
  }

  @Expose()
  hasSecondaryBorrowerTemp() {
    return this.persons.length > 1;
  }

  @Expose()
  getPrimaryBorrower() {
    return this.persons[0];
  }

  @Expose()
  getSecondaryBorrower() {
    return this.persons[1];
  }

  @Expose()
  getPrimaryBorrowerFullName() {
    const borrower = this.getPrimaryBorrower();

    if (!borrower) return '';

    const names: string[] = [];
    if (borrower.givenName) names.push(borrower.givenName);
    if (borrower.middleName) names.push(borrower.middleName);
    if (borrower.fathersLastName) names.push(borrower.fathersLastName);
    if (borrower.mothersLastName) names.push(borrower.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getSecondaryBorrowerFullName() {
    const borrower = this.getSecondaryBorrower();

    if (!borrower) return '';

    const names: string[] = [];
    if (borrower.givenName) names.push(borrower.givenName);
    if (borrower.middleName) names.push(borrower.middleName);
    if (borrower.fathersLastName) names.push(borrower.fathersLastName);
    if (borrower.mothersLastName) names.push(borrower.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getPrimaryBorrowerInformation() {
    const borrower = this.getPrimaryBorrower();

    return borrower
      ? [
          {
            icon: 'work',
            itemName: '',
            itemValue: borrower.workClassification,
          },
          ...this.generateDocumentList(borrower.documents),
          { icon: 'phone', itemName: '', itemValue: borrower.mobilePhoneNumber },
          { icon: 'mail', itemName: '', itemValue: borrower.email },
          {
            icon: 'rings',
            itemName: '',
            itemValue: borrower.maritalStatus,
          },
        ]
      : [];
  }

  @Expose()
  getSecondaryBorrowerInformation() {
    const borrower = this.getSecondaryBorrower();

    return borrower
      ? [
          {
            icon: 'user',
            itemName: '',
            itemValue: this.getSecondaryBorrowerFullName(),
          },
          {
            icon: 'work',
            itemName: '',
            itemValue: borrower.workClassification,
          },
          ...this.generateDocumentList(borrower.documents),
          { icon: 'phone', itemName: '', itemValue: borrower.mobilePhoneNumber },
          { icon: 'mail', itemName: '', itemValue: borrower.email },
        ]
      : [];
  }

  generateDocumentList(documents) {
    const documentList = documents.map((document) => {
      return {
        icon: 'document',
        itemName: '',
        itemValue: `${document.documentType}: ${document.documentNumber}`,
      };
    });

    return documentList;
  }

  @Expose()
  getRulesList() {
    const mandatoryRules = this.getRulesFromVariable(this.mandatoryVariables);
    const levelOneRules = this.getRulesFromVariable(this.levelOneVariables);
    const externalRules = this.getRulesFromVariable(this.externalVariables);

    return [...mandatoryRules, ...levelOneRules, ...externalRules];
  }

  getRulesFromVariable(variable) {
    if (!variable) return [];

    if (!variable.rules) return [];

    const rules = variable.rules.map((rule) => {
      const newRule = {
        code: rule.code,
        name: rule.name,
        description: rule.description,
        periods: rule.periods,
        type: '',
        details: [],
        verdicts: [] as string[],
        finalVerdict: 'no-data',
      };

      let ruleType = '';

      let newResult = rule.result.map((borrower) => {
        const profile = borrower.principalBorrower ? 'Titular' : 'Cónyuge';

        newRule.verdicts.push(borrower.verdict);

        if (borrower.details.length > 0) {
          ruleType = borrower.details[0].type;

          return borrower.details.map((detail) => {
            switch (ruleType) {
              case 'transaction':
                return {
                  profile,
                  institution: detail.institution,
                  category: detail.accountBalanceName,
                  debt: detail.balance,
                };
              case 'classification':
                return {
                  profile,
                  description: detail.description,
                  periods: detail.periods.length,
                };
              case 'loans':
                return {
                  profile,
                  institutions: detail.data
                    .map((d) => {
                      return d.institution;
                    })
                    .join(', '),
                  institutionsQuantity: detail.data.length,
                  institutionsDebt: detail.data.reduce(
                    (n, { balance }) => n + parseFloat(balance),
                    0,
                  ),
                };

              case 'external':
                return {
                  profile,
                  institutions: detail.data
                    .map((d) => {
                      return d.institution;
                    })
                    .join(', '),
                  periods: detail.data
                    .map((d) => {
                      return d.period;
                    })
                    .join(', '),
                  institutionsDebt: detail.data.reduce(
                    (n, { balance }) => n + parseFloat(balance),
                    0,
                  ),
                };
            }
          });
        } else {
          return [];
        }
      });

      newResult = [].concat(...newResult);

      newRule.type = ruleType;
      newRule.details = newResult;

      const hasNotApprovedVerdict = newRule.verdicts.includes('not-approved');
      const hasApprovedVerdict = newRule.verdicts.includes('approved');

      const finalVerdict = hasNotApprovedVerdict
        ? 'not-approved'
        : hasApprovedVerdict
        ? 'approved'
        : 'no-data';

      newRule.finalVerdict = finalVerdict;

      return newRule;
    });

    return rules;
  }
}
