import { Component, Directive, Input, Host, Self, Optional } from '@angular/core';
import { BaseComponent } from '@vivela/xplat/core';

import { VivelaIconName } from '../icon/types';

@Component({
  selector: 'vv-button',
  templateUrl: 'button.component.html',
})
export class ButtonComponent extends BaseComponent {
  @Input() color: string;
  @Input() icon: VivelaIconName;

  isButtonIcon: boolean;
  isButtonTextIcon: boolean;

  class: string;

  ngOnInit() {
    this.class = this.getClass();
  }

  private getClass() {
    const classes = ['btn'];

    if (this.color) {
      classes.push(`btn-${this.color}`);
    }

    if (this.isButtonIcon) {
      classes.push('btn-icon');
    }

    return classes.join(' ');
  }
}

@Directive({
  selector: 'vv-button[buttonIcon]',
})
export class ButtonIconDirective {
  constructor(@Host() @Self() @Optional() host: ButtonComponent) {
    host.isButtonIcon = true;
  }
}

@Directive({
  selector: 'vv-button[buttonTextIcon]',
})
export class ButtonTextIconDirective {
  constructor(@Host() @Self() @Optional() host: ButtonComponent) {
    host.isButtonTextIcon = true;
  }
}
