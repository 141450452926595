export enum Env {
  DEV = 'dev',
  STG = 'stg',
  PROD = 'prod',
  NONE = 'none',
}

/**
 * Workspace shared environment properties
 */
export interface IEnvironment {
  baseUrl: string;
  authBaseUrl: string;
  authCallbackUrl: string;
  googleAuthClientId: string;
  recaptchaSiteKey: string;
  xApiKey?: string;
  environment: Env;
  isProduction(): boolean;
  isStaging(): boolean;
  isDevelopment(): boolean;
  idleTimerDurationInSeconds: number;
}

export const asPartialIEnvironment = <T extends Partial<IEnvironment>>(t: T) => t;
