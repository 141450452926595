import { Component } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vivela-auth-error',
  templateUrl: 'auth-error.component.html',
})
export class AuthErrorComponent extends BaseComponent {
  message = 'Todavía no estás registrado.';
  action = 'Comunícate con el administrador';

  constructor() {
    super();
  }
}
