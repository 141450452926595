import { Expose, plainToClass, Transform } from 'class-transformer';
import { Customer, Prospecting } from '.';
import { maritalStatuses } from '../base';
import { intervalToDuration } from 'date-fns';

export class ApplicationListItem {
  @Expose()
  uuid: string;

  @Expose()
  prospectingUuid: string;

  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  date: Date;

  @Expose()
  status: string;

  @Expose()
  daysAfterBegin: number;

  @Transform(({ value }) => plainToClass(Prospecting, value), {
    toClassOnly: true,
  })
  prospecting: Prospecting;

  @Transform(({ value }) => plainToClass(Customer, value), {
    toClassOnly: true,
  })
  customer: Customer;

  @Expose()
  property: any;

  @Expose()
  getDaysAfterBegin() {
    return intervalToDuration({
      start: this.date,
      end: new Date(),
    }).days;
  }

  @Expose()
  getPropertyName() {
    return this.property?.name || '--';
  }
}
