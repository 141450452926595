import { ValidatorFn } from '@angular/forms';
import { SelectOption } from '../..';
import { Observable } from 'rxjs';

export type ControlType =
  | 'textfield'
  | 'autocomplete'
  | 'datepicker'
  | 'switch'
  | 'select'
  | 'chips'
  | 'chips-multi-select'
  | 'chips-select'
  | 'textview'
  | 'slider'
  | 'checkbox'
  | 'chips-autocomplete'
  | 'file'
  | 'radio-button'
  | '';

export class FormFieldBase<T> {
  value?: T;
  key: string;
  label: string;
  extLabel?: string;
  controlType: string;
  type: string;
  min: number;
  max: number;
  step: number;
  tooltip?: string;
  hint?: string;
  mask?: any;
  unmask?: boolean;
  validators: ValidatorFn | ValidatorFn[] | null;
  options: Array<{ label: string; value: string }> | string[];
  previewSrc: string;
  fileLabel: string;
  fileDetail: string;
  optionsLoadMethod: Observable<any>;
  dynamicOptions?: {
    formValue: string;
    function: (value: string) => SelectOption[];
  };
  currencyTransform?: {
    code: string;
  };
  items: { [key: string]: string };
  cssClass?: string;
  labelCss?: string;
  textCss?: string;
  unit?: string;
  hidden?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  isLoading?: boolean;
  loadOptionsFail?: boolean;
  startDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  messages?: { [key: string]: string };
  dynamicSubTittle?: {
    value: string[];
    text: string;
  };
  accept?: string;

  constructor(fields: {
    value?: T;
    key?: string;
    label?: string;
    extLabel?: string;
    controlType?: string;
    type?: string;
    min?: number;
    max?: number;
    step?: number;
    tooltip?: string;
    hint?: string;
    mask?: any;
    unmask?: boolean;
    validators: ValidatorFn | ValidatorFn[] | null;
    options?: Array<{ label: string; value: string }> | string[];
    previewSrc?: string;
    fileLabel?: string;
    fileDetail?: string;
    optionsLoadMethod?: Observable<any>;
    dynamicOptions?: {
      formValue: string;
      function: (value: string) => SelectOption[];
    };
    currencyTransform?: {
      code: string;
    };
    items?: { [key: string]: string };
    cssClass?: string;
    labelCss?: string;
    textCss?: string;
    unit?: string;
    hidden?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    isLoading?: boolean;
    loadOptionsFail?: boolean;
    startDate?: Date;
    minDate?: Date;
    maxDate?: Date;
    messages?: { [key: string]: string };
    dynamicSubTittle?: {
      value: string[];
      text: string;
    };
    accept?: string;
  }) {
    this.value = fields.value;
    this.key = fields.key || '';
    this.label = fields.label || '';
    this.extLabel = fields.extLabel || '';
    this.controlType = fields.controlType || '';
    this.type = fields.type || '';
    this.min = fields.min || 0;
    this.max = fields.max || 0;
    this.step = fields.step || 1;
    this.tooltip = fields.tooltip;
    this.hint = fields.hint;
    this.mask = fields.mask;
    this.unmask = fields.unmask || true;
    this.validators = fields.validators;
    this.options = fields.options || [];
    this.previewSrc = fields.previewSrc || '';
    this.fileLabel = fields.fileLabel || '';
    this.fileDetail = fields.fileDetail || '';
    this.dynamicOptions = fields.dynamicOptions;
    this.currencyTransform = fields.currencyTransform;
    this.items = fields.items || {};
    this.cssClass = fields.cssClass || '';
    this.labelCss = fields.labelCss || '';
    this.textCss = fields.textCss || '';
    this.unit = fields.unit || '';
    this.hidden = fields.hidden || false;
    this.disabled = fields.disabled;
    this.readonly = fields.readonly || false;
    this.isLoading = fields.isLoading || false;
    this.optionsLoadMethod = fields.optionsLoadMethod || new Observable<any>();
    this.loadOptionsFail = fields.loadOptionsFail || false;
    this.startDate = fields.startDate;
    this.minDate = fields.minDate;
    this.maxDate = fields.maxDate;
    this.messages = fields.messages;
    this.dynamicSubTittle = fields.dynamicSubTittle;
    this.accept = fields.accept;
  }
}
