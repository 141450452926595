import { Injectable } from '@angular/core';
import { Application, ApplicationListItem, ApplicationReference } from '../../models';
import { plainToClass } from 'class-transformer';

import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { HttpHeaders } from '@angular/common/http';

type CreateApplicationPayload = {
  prospectingUuid: string;
};

type CreateReferencePayload = {
  name: string;
  lastName: string;
  phoneNumber: string;
  relation: string;
};

@Injectable()
export class ApplicationService {
  constructor(private dataService: DataService) {}

  getList() {
    return this.dataService
      .get<{ items: ApplicationListItem[] }>('application/list')
      .pipe(
        map((res) => {
          const items = plainToClass(ApplicationListItem, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getPreviousList(cursor: string) {
    return this.dataService
      .get<{ items: ApplicationListItem[] }>('application/list', {
        params: {
          previousCursor: cursor,
        },
      })
      .pipe(
        map((res) => {
          const items = plainToClass(ApplicationListItem, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getNextList(cursor: string) {
    return this.dataService
      .get<{ items: ApplicationListItem[] }>('application/list', {
        params: {
          nextCursor: cursor,
        },
      })
      .pipe(
        map((res) => {
          const items = plainToClass(ApplicationListItem, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getListByDocumentNumber(documentNumber: string) {
    return this.dataService
      .post<{ items: ApplicationListItem[] }>('application/list/by-document-number', {
        documentNumber,
      })
      .pipe(
        map((res) => {
          const items = plainToClass(ApplicationListItem, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getApplication(uuid: any) {
    return this.dataService
      .get<Application>(`application/${uuid}`)
      .pipe(map((res) => plainToClass(Application, res.data)));
  }

  uploadApplicationFile(payload) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this.dataService
      .post(`application/files/upload`, payload, { headers })
      .pipe(map((res) => res.data));
  }

  getApplicationFile(uuid: any) {
    return this.dataService
      .get(`application/files/list?applicationUuid=${uuid}`)
      .pipe(map((res) => res.data));
  }

  createApplication(payload: CreateApplicationPayload) {
    return this.dataService
      .post<Application>(`application`, payload)
      .pipe(map((res) => res.data));
  }

  saveApplication(applicationUuid: string, payload: CreateApplicationPayload) {
    return this.dataService
      .patch<Application>(`application/${applicationUuid}`, payload)
      .pipe(map((res) => res.data));
  }

  getApplicationByProspectingUuid(prospectingUuid: string) {
    return this.dataService
      .get<Application>(`application/get-by-prospecting/${prospectingUuid}`)
      .pipe(map((res) => res.data));
  }

  addReference(applicationUuid: string, payload: CreateReferencePayload) {
    return this.dataService
      .post<ApplicationReference>(`applications/${applicationUuid}/references`, payload)
      .pipe(map((res) => res.data));
  }

  getReferences(applicationUuid: string) {
    return this.dataService
      .get<ApplicationReference[]>(`applications/${applicationUuid}/references`)
      .pipe(map((res) => res.data));
  }

  deleteReference(applicationUuid: string, referenceUuid: string) {
    return this.dataService
      .delete(`applications/${applicationUuid}/references/${referenceUuid}`)
      .pipe(map((res) => res));
  }

  getEconomicActivityParameters() {
    return this.dataService
      .get(`application-parameters/economic-activity`)
      .pipe(map((res) => res.data));
  }

  deleteDocument(applicationUuid: string, fileUuid: string) {
    return this.dataService
      .delete(`application/files/${fileUuid}`, {
        params: {
          applicationUuid: applicationUuid,
        },
      })
      .pipe(map((res) => res.data));
  }

  downloadDocument(applicationUuid: string, fileUuid: string) {
    return this.dataService
      .get<string>(`application/files/download/${fileUuid}`, {
        params: {
          applicationUuid: applicationUuid,
        },
      })
      .pipe(map((res) => res.data));
  }

  savePep(applicationUuid: string, payload) {
    return this.dataService
      .post(`applications/${applicationUuid}/pep`, payload)
      .pipe(map((res) => res.data));
  }

  registerApplication(applicationUuid: string) {
    return this.dataService
      .post('application/register', {
        applicationUuid,
      })
      .pipe(map((res) => res.data));
  }

  downloadApplication(applicationUuid: string) {
    return this.dataService.getBlob(`application/download?uuid=${applicationUuid}`);
  }

  assignApplication(applicationUuid: string, advisorEmail) {
    return this.dataService
      .post<{ code: string }>(`application/assign/${applicationUuid}`, {
        email: advisorEmail,
      })
      .pipe(map((res) => res.data));
  }
}
