<div *ngIf="isListIcon; else list" class="list">
  <div class="list__item" *ngFor="let item of listItems">
    <vv-icon *ngIf="item.icon" [icon]="item.icon"></vv-icon>
    <div class="list__item__value">
      {{ item.itemValue }}
    </div>
  </div>
</div>
<ng-template #list>
  <div class="list">
    <div class="list__item" *ngFor="let item of listItems">
      <div class="list__item__decorator">
        <vv-icon icon="list" size="sm"></vv-icon>
      </div>
      <div class="list__item__name">{{ item.itemName }}:</div>
      <div class="list__item__value">
        <vv-switch
          *ngIf="item.type == 'boolean'; else string"
          disable
          label=""
          [fieldName]="item.itemName"
          [checked]="item.itemValue"
        ></vv-switch>
        <ng-template #string>
          <span class="text-bold"> {{ item.itemValue }}</span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
