import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { finalize } from 'rxjs/operators';
import { FinancialProductService } from '@vivela/xplat/api';

type StateModel = {
  isLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'financial_product',
})
@Injectable()
export class FinancialProductState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  constructor(private financialProductService: FinancialProductService) {
    super();
  }

  getFinancialProducts(year: number) {
    this.patchState(startLoader);

    return this.financialProductService.getFinancialProducts(year).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getFinancialProductsByYears(year: number[]) {
    this.patchState(startLoader);

    return this.financialProductService.getFinancialProductsByYears(year).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
