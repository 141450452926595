import { Directive, OnDestroy } from '@angular/core';

// libs
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { penCurrencyAmount } from '@vivela/xplat/api';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  private _destroy$: Subject<boolean>;
  penCurrencyAmountMask = penCurrencyAmount();

  get destroy$() {
    if (!this._destroy$) {
      // Perf optimization:
      // since this is likely used as base component everywhere
      // only construct a Subject instance if actually used
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }

  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }

  protected subscribe<T>(obs: Observable<T>) {
    obs.pipe(takeUntil(this.destroy$)).subscribe();
  }
}
