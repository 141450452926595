import { Expose, Type } from 'class-transformer';
import * as moment from 'moment';
import { GovernmentEmailModel } from './government-email.model';

export class DispatchSingleModel {
  @Expose()
  uuid: string;

  @Expose()
  type: string;

  @Expose()
  createdAt: Date;

  @Expose()
  sentAt: Date;

  @Expose()
  responseRecipient: string;

  @Expose()
  getShortCreatedAt() {
    return moment(this.createdAt).format('DD/MM/YYYY, hh:mm A');
  }
}
