/* eslint-disable @typescript-eslint/no-explicit-any*/
import {
  Component,
  Directive,
  Host,
  Input,
  Optional,
  Self,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from '@vivela/xplat/core';
import { ChipItems } from './types';

@Component({
  selector: 'vv-chips',
  templateUrl: 'chips.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true,
    },
  ],
})
export class ChipsComponent extends BaseComponent implements ControlValueAccessor {
  @Input() items: ChipItems;
  @Input() validate: boolean;

  multiple: boolean;
  disable: boolean;
  values = {};

  writeValue(obj: any): void {
    this.values = {};
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback: any = () => {
    // do nothing.
  };

  onTouchedCallback: any = () => {
    // do nothing.
  };

  valuesIsEmpty() {
    return Object.keys(this.values).length == 0;
  }

  onChipSelected(itemKey: string) {
    if (this.values[itemKey]) {
      delete this.values[itemKey];
    } else {
      if (!this.multiple) {
        this.values = {};
      }
      this.values[itemKey] = this.items[itemKey];
    }

    this.onChangeCallback(Object.keys(this.values).length == 0 ? '' : this.values);
  }
}

@Directive({
  selector: 'vv-chips[multiple]',
})
export class ChipsMultipleDirective {
  constructor(@Host() @Self() @Optional() host: ChipsComponent) {
    host.multiple = true;
  }
}

@Directive({
  selector: 'vv-chips[disable]',
})
export class ChipsDisableDirective {
  constructor(@Host() @Self() @Optional() host: ChipsComponent) {
    host.disable = true;
  }
}
