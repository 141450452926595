import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class UpdateSpouseShareInfo {
  static readonly type = '[SpouseShareInfo] Update Spouse Share Info';

  constructor(public payload: SpouseShareInfoStateModel) {}
}

export class ClearSpouseShareInfo {
  static readonly type = '[Auth] Clear Spouse Share Info';
}

type SpouseShareInfoStateModel = {
  givenName: string;
  middleName: string;
  fathersLastname: string;
  mothersLastname: string;
  marriedName?: string;
};

@State<SpouseShareInfoStateModel>({
  name: 'SpouseShareInfo',
  defaults: {
    givenName: '',
    middleName: '',
    fathersLastname: '',
    mothersLastname: '',
    marriedName: '',
  },
})
@Injectable()
export class SpouseShareInfoState {
  @Selector()
  static spouseInfoPayload(state: SpouseShareInfoStateModel): {
    givenAndMiddleName: string;
    fathersLastname: string;
    mothersLastname: string;
    marriedName?: string;
  } {
    return {
      givenAndMiddleName: state.givenName.concat(` ${state.middleName}`).trim(),
      fathersLastname: state.fathersLastname,
      mothersLastname: state.mothersLastname,
      marriedName: state.marriedName,
    };
  }

  @Action(UpdateSpouseShareInfo)
  updateSpouseShareInfo(
    ctx: StateContext<SpouseShareInfoStateModel>,
    { payload }: UpdateSpouseShareInfo,
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ...payload,
    });
  }

  @Action(ClearSpouseShareInfo)
  clearSpouseShareInfo(ctx: StateContext<SpouseShareInfoStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ...{
        givenName: '',
        middleName: '',
        fathersLastname: '',
        mothersLastname: '',
        marriedName: '',
      },
    });
  }
}
