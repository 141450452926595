import { Component, OnInit } from '@angular/core';
import { getLocaleCurrencySymbol, getCurrencySymbol } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

// xplat
import { AppBaseComponent } from '@vivela/xplat/web/features';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@vivela/xplat/environment';

@Component({
  selector: 'vivela-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends AppBaseComponent implements OnInit {
  cheatLocalSymbol = getLocaleCurrencySymbol('es-PE');
  cheatSymbol = getCurrencySymbol('PEN', 'wide');

  constructor(private router: Router, private gtmService: GoogleTagManagerService) {
    super();
  }

  ngOnInit() {
    // push GTM data layer for every visited page
    if (environment.isProduction()) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };

          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }
}
