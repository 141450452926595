import { Component, Input, OnInit } from '@angular/core';
import { FormFieldBase } from '../../../dynamic-form-field';

@Component({
  selector: 'vv-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() title: string;
  @Input() formFields: Array<FormFieldBase<any>>;

  constructor() {}

  ngOnInit(): void {}
}
