import { Expose } from 'class-transformer';

export class ApplicationReference {
  @Expose()
  uuid: any;

  @Expose()
  name: any;

  @Expose()
  lastName: any;

  @Expose()
  phoneNumber: any;

  @Expose()
  relation: any;
}
