import { Expose, Transform } from 'class-transformer';
import { PrequalificationRule } from '.';
import { maritalStatuses } from '../base';
import { PrequalificationReportPerson } from './prequalification-report-person.model';

import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { IMaskPipe } from 'angular-imask';
import { penCurrencyAmount } from '../masks';

export class PrequalificationReport {
  @Expose()
  uuid: string;

  @Expose()
  result: string;

  @Expose()
  prospect: PrequalificationReportPerson;

  @Expose()
  prospecting: { uuid: string };

  @Expose()
  report: { rules: PrequalificationRule[] };

  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  date: Date;

  @Expose()
  hasSecondaryBorrower() {
    return !!this.prospect.spouse;
  }

  @Expose()
  getPrimaryBorrower() {
    return this.prospect;
  }

  @Expose()
  getSecondaryBorrower() {
    return this.prospect.spouse;
  }

  @Expose()
  getRules() {
    return this.report.rules.map((e) => {
      const rule = e;
      for (const result of rule.results) {
        const showResult = result.details?.length > 0;
        result.showDetails = showResult;
        result.detailHeaders = showResult ? this.getRulesHeaders(result) : [];
        result.detailFields = showResult ? this.getRulesFields(result) : [];
        result.profile = this.getResultProfile(result);
      }
      return rule;
    });
  }

  getResultProfile(result) {
    const profile = result.isPrincipalProspect ? 'Titular' : 'Cónyuge';
    return profile;
  }

  getRulesFields(result) {
    const penCurrencyAmountMask = new IMaskPipe();

    const [detail] = result.details;
    const ruleType = detail.type;

    switch (ruleType) {
      case 'negative-database':
        return result.details.map((detail) => {
          let detailMapped = '';
          if (detail.motive) {
            detailMapped += detail.motive;
          }

          if (detail.observation) {
            detailMapped += ` - ${detail.observation}`;
          }

          return [detailMapped];
        });
      case 'mortgage-registry':
        return result.details.map((detail) => {
          return [detail.numOpe, detail.numSol];
        });
      case 'transaction':
        return result.details.map((detail) => {
          return [
            detail.institution.name,
            formatInTimeZone(new Date(detail.reportDate), 'UTC', 'dd/MM/yyyy'),
            penCurrencyAmountMask.transform(
              detail.balance.toString(),
              penCurrencyAmount(),
            ),
          ];
        });
      case 'classification':
        return result.details.map((detail) => {
          return [
            detail.classification.name,
            formatInTimeZone(new Date(detail.reportDate), 'UTC', 'dd/MM/yyyy'),
            detail.institution.name,
            penCurrencyAmountMask.transform(
              detail.balance.toString(),
              penCurrencyAmount(),
            ),
          ];
        });
      case 'loan':
        return result.details.map((detail) => {
          return [
            detail.institution.name,
            formatInTimeZone(new Date(detail.reportDate), 'UTC', 'dd/MM/yyyy'),
            penCurrencyAmountMask.transform(
              detail.balance.toString(),
              penCurrencyAmount(),
            ),
          ];
        });
      case 'external':
        return result.details.map((detail) => {
          return [
            detail.entities.map((e) => ` · ${e.name}`).join('\r\n'),
            formatInTimeZone(new Date(detail.period), 'UTC', 'dd/MM/yyyy'),
            penCurrencyAmountMask.transform(
              detail.amount.toString(),
              penCurrencyAmount(),
            ),
          ];
        });
      case 'entities':
        return result.details.map((e) => {
          return [
            e.numberOfEntities,
            e.entities.map((e) => ` · ${e.name}`).join('\r\n'),
            formatInTimeZone(new Date(detail.reportDate), 'UTC', 'dd/MM/yyyy'),
          ];
        });
      case 'payment-capacity':
        return detail.obligations.map((e) => {
          return [
            e.name,
            penCurrencyAmountMask.transform(e.subtotal.toString(), penCurrencyAmount()),
            (parseFloat(e.multiplier) * 100).toFixed(2) + '%',
            penCurrencyAmountMask.transform(e.total.toString(), penCurrencyAmount()),
          ];
        });
    }
  }

  getRulesHeaders(result) {
    const ruleType = result.details[0].type;
    switch (ruleType) {
      case 'negative-database':
        return ['Observaciones'];
      case 'mortgage-registry':
        return ['Número de Operación', 'Número de Solicitud'];
      case 'transaction':
        return ['Institución', 'Periodo', 'Deuda'];
      case 'classification':
        return ['Clasificación', 'Periodo', 'Institución', 'Deuda'];
      case 'loan':
        return ['Institución', 'Periodo', 'Deuda'];
      case 'external':
        return ['Institución', 'Periodo', 'Deuda'];
      case 'entities':
        return ['Número de entidades', 'Entidades', 'Periodo'];
      case 'payment-capacity':
        return ['Categoría', 'Sub total', 'Factor de cálculo', 'Total'];
    }
  }

  @Expose()
  getPrimaryBorrowerFullName() {
    const borrower = this.getPrimaryBorrower();

    if (!borrower) return '';

    const names: string[] = [];
    if (borrower.givenName) names.push(borrower.givenName);
    if (borrower.middleName) names.push(borrower.middleName);
    if (borrower.fathersLastName) names.push(borrower.fathersLastName);
    if (borrower.mothersLastName) names.push(borrower.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getSecondaryBorrowerFullName() {
    const borrower = this.getSecondaryBorrower();

    if (!borrower) return '';

    const names: string[] = [];
    if (borrower.givenName) names.push(borrower.givenName);
    if (borrower.middleName) names.push(borrower.middleName);
    if (borrower.fathersLastName) names.push(borrower.fathersLastName);
    if (borrower.mothersLastName) names.push(borrower.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getPrimaryBorrowerInformation(): any {
    const borrower = this.getPrimaryBorrower();

    return borrower
      ? [
          {
            icon: 'work',
            itemName: '',
            itemValue: borrower.workClassification.name,
          },
          {
            icon: 'document',
            itemName: '',
            itemValue: `${borrower.documentType.name}: ${borrower.documentNumber}`,
          },
          ...(borrower.mobilePhoneNumber
            ? [{ icon: 'phone', itemName: '', itemValue: borrower.mobilePhoneNumber }]
            : []),
          ...(borrower.email
            ? [{ icon: 'mail', itemName: '', itemValue: borrower.email }]
            : []),
          {
            icon: 'rings',
            itemName: '',
            itemValue: maritalStatuses[borrower.maritalStatus],
          },
        ]
      : [];
  }

  @Expose()
  getSecondaryBorrowerInformation(): any {
    const borrower = this.getSecondaryBorrower();

    return borrower
      ? [
          {
            icon: 'user',
            itemName: '',
            itemValue: this.getSecondaryBorrowerFullName(),
          },
          {
            icon: 'work',
            itemName: '',
            itemValue: borrower.workClassification.name,
          },
          {
            icon: 'document',
            itemName: '',
            itemValue: `${borrower.documentType.name}: ${borrower.documentNumber}`,
          },
          ...(borrower.mobilePhoneNumber
            ? [{ icon: 'phone', itemName: '', itemValue: borrower.mobilePhoneNumber }]
            : []),
          ...(borrower.email
            ? [{ icon: 'mail', itemName: '', itemValue: borrower.email }]
            : []),
        ]
      : [];
  }

  @Expose()
  getPrimaryBorrowerInformationList() {
    const borrower = this.getPrimaryBorrower();

    return borrower
      ? [
          {
            icon: 'user',
            itemName: '',
            itemValue: this.getPrimaryBorrowerFullName(),
          },
          {
            icon: 'work',
            itemName: '',
            itemValue: borrower.workClassification.name,
          },
          {
            icon: 'document',
            itemName: '',
            itemValue: `${borrower.documentType.name}: ${borrower.documentNumber}`,
          },
          { icon: 'phone', itemName: '', itemValue: borrower.mobilePhoneNumber },
          { icon: 'mail', itemName: '', itemValue: borrower.email },
          {
            icon: 'rings',
            itemName: '',
            itemValue: maritalStatuses[borrower.maritalStatus],
          },
        ]
      : [];
  }

  @Expose()
  getSecondaryBorrowerInformationList() {
    const borrower = this.getSecondaryBorrower();

    return borrower
      ? [
          {
            icon: 'user',
            itemName: '',
            itemValue: this.getSecondaryBorrowerFullName(),
          },
          {
            icon: 'work',
            itemName: '',
            itemValue: borrower.workClassification.name,
          },
          {
            icon: 'document',
            itemName: '',
            itemValue: `${borrower.documentType.name}: ${borrower.documentNumber}`,
          },
          { icon: 'phone', itemName: '', itemValue: borrower.mobilePhoneNumber },
          { icon: 'mail', itemName: '', itemValue: borrower.email },
          {
            icon: 'rings',
            itemName: '',
            itemValue: borrower.maritalStatus,
          },
        ]
      : [];
  }

  @Expose()
  getShortDate() {
    const month = ('0' + (this.date.getMonth() + 1)).slice(-2);
    const date = ('0' + this.date.getDate()).slice(-2);
    const year = this.date.getFullYear();

    return date + '/' + month + '/' + year;
  }
}
