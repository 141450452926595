import { asPartialIEnvironment, Env, IEnvironment } from '../environment.interface';
import { environmentBase } from './environment.base';

export const environmentProd: IEnvironment = {
  ...environmentBase,
  ...asPartialIEnvironment({
    environment: Env.PROD,
    baseUrl: 'https://api.backoffice.vivela.lat/backoffice',
    authBaseUrl: 'https://auth.backoffice.vivela.lat',
    googleAuthClientId: '1hle4dtsqsrdn53i7ikm4v428p',
    authCallbackUrl: 'https://backoffice.vivela.lat/callback',
    recaptchaSiteKey: '6LeRcYohAAAAAHy6vC8hCsiOf4cn-XR1LsTVTjRL',
  }),
};
