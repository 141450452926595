/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { finalize } from 'rxjs/operators';
import { ApplicationListItem, ApplicationService } from '@vivela/xplat/api';

type PaginationCursor = {
  type: 'previous' | 'next';
  value: string;
  pageIndex: number;
};

type StateModel = {
  isLoading: boolean;
  applications: ApplicationListItem[];
  lastCursor: PaginationCursor;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'application',
})
@Injectable()
export class ApplicationState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  @Computed()
  get applications() {
    return this.snapshot.applications;
  }

  constructor(private applicationService: ApplicationService) {
    super();
  }

  getApplicationList() {
    this.patchState(startLoader);

    return this.applicationService.getList().pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getPreviousApplicationList(cursor: string) {
    this.patchState(startLoader);

    return this.applicationService.getPreviousList(cursor).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getNextApplicationList(cursor: string) {
    this.patchState(startLoader);

    return this.applicationService.getNextList(cursor).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getApplicationListByDocumentNumber(documentNumber: string) {
    this.patchState(startLoader);

    return this.applicationService.getListByDocumentNumber(documentNumber).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getApplication(uuid: string) {
    this.patchState(startLoader);

    return this.applicationService.getApplication(uuid).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  uploadApplicationFile(payload) {
    return this.applicationService
      .uploadApplicationFile(payload)
      .pipe(finalize(() => {}));
  }

  getApplicationFile(uuid: string) {
    return this.applicationService.getApplicationFile(uuid).pipe(finalize(() => {}));
  }

  createApplication(payload: any) {
    this.patchState(startLoader);

    return this.applicationService.createApplication(payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  saveApplication(applicationUuid: string, payload: any) {
    this.patchState(startLoader);

    return this.applicationService.saveApplication(applicationUuid, payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getApplicationByProspectingUuid(prospectingUuid: string) {
    return this.applicationService.getApplicationByProspectingUuid(prospectingUuid).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  addReference(applicationUuid: string, payload: any) {
    return this.applicationService.addReference(applicationUuid, payload).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  getReferences(applicationUuid: string) {
    return this.applicationService.getReferences(applicationUuid).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  deleteReference(applicationUuid: string, referenceUuid: string) {
    return this.applicationService.deleteReference(applicationUuid, referenceUuid).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  getEconomicActivityParameters() {
    return this.applicationService.getEconomicActivityParameters().pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  deleteDocument(applicationUuid: string, fileUuid: string) {
    return this.applicationService.deleteDocument(applicationUuid, fileUuid).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  downloadDocument(applicationUuid: string, fileUuid: string) {
    return this.applicationService.downloadDocument(applicationUuid, fileUuid).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  savePartialApplication(applicationUuid: string, payload: any) {
    return this.applicationService.saveApplication(applicationUuid, payload).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  savePep(applicationUuid: string, payload: any) {
    return this.applicationService.savePep(applicationUuid, payload).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  registerApplication(applicationUuid: string) {
    return this.applicationService.registerApplication(applicationUuid).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  downloadApplication(applicationUuid: string) {
    return this.applicationService.downloadApplication(applicationUuid).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  assignApplication(applicationUuid: string, advisorEmail: string) {
    return this.applicationService.assignApplication(applicationUuid, advisorEmail).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }
}
