import { asPartialIEnvironment, Env } from '../environment.interface';

/**
 * Reduce the most commonly used environment values here
 */
export const environmentBase = asPartialIEnvironment({
  idleTimerDurationInSeconds: 900,
  isDevelopment(): boolean {
    return this.environment === Env.DEV;
  },
  isStaging(): boolean {
    return this.environment === Env.STG;
  },
  isProduction(): boolean {
    return this.environment === Env.PROD;
  },
});
