import { Expose } from 'class-transformer';

export class PrequalificationCreatedFromExcelModel {
  @Expose()
  created: string | boolean;

  @Expose()
  customerIdentifier: string;

  @Expose()
  error?: string;
}
