import { Directive, Input } from '@angular/core';
import { BaseComponent } from '@vivela/xplat/core';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class StepperBaseComponent extends BaseComponent {
  @Input() steps: number[];
  @Input() selectedStepIndex = 0;

  abstract goToNextStep(): void;
  abstract goToPreviousStep(): void;
}
