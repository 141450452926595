<div class="overflow-hidden">
  <div class="header">
    <div class="header__logo">
      <img src="assets/logo.png" />
    </div>
    <div class="menu">
      <a *ngFor="let item of menuItems" [href]="item.url" class="menu__item">{{
        item.text
      }}</a>
    </div>
    <div class="header__profile">
      <img
        src="https://www.pngkit.com/png/full/115-1150342_user-avatar-icon-iconos-de-mujeres-a-color.png"
      />
    </div>
  </div>
</div>
