import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsDataPluginModule } from '@angular-ru/ngxs';

import { ApiModule, ENV_CONFIG, EnvironmentConfig } from '@vivela/xplat/api';

import { CORE_STATES } from './states';
import { environment } from '@vivela/xplat/environment';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { AuthState } from './states';

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
    NgxsModule.forRoot(CORE_STATES, { developmentMode: !environment.isProduction() }),
    NgxsDataPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: AuthState,
      storage: StorageOption.SessionStorage,
    }),
  ],
})
export class StoreModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<StoreModule> {
    return {
      ngModule: StoreModule,
      providers: [
        {
          provide: ENV_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
