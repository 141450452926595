import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@vivela/xplat/core';

@Directive()
export abstract class CheckBoxBaseComponent extends BaseComponent {
  @Input() checked: boolean;
  @Output() readonly checkedChange = new EventEmitter<boolean>();

  toggle(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
