import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vv-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  title = 'Angular-Interceptor';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; content: string },
    private mdDialogRef: MatDialogRef<ErrorDialogComponent>,
  ) {}

  closeDialog() {
    this.mdDialogRef.close();
  }
}
