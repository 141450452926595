import { Expose } from 'class-transformer';

export class Session {
  @Expose()
  AccessToken: string;

  @Expose()
  ExpiresIn: number;

  @Expose()
  IdToken: string;

  @Expose()
  RefreshToken: string;

  @Expose()
  forceNewPassword: boolean;
}
