import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { MAT_CUSTOM_COMPONENTS } from './material-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '../ui/';
import { DynamicFormFieldService } from './services';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { IMaskModule } from 'angular-imask';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateMaskDirective } from './directives';
import { SafePipeModule } from '@angular-ru/cdk/pipes';

const COMPONENTS = [DynamicFormFieldComponent, ...MAT_CUSTOM_COMPONENTS];
const MaterialUIComponents = [
  MatInputModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatChipsModule,
  MatIconModule,
  MatButtonModule,
  MatTableModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatTabsModule,
  MatProgressBarModule,
];

@NgModule({
  imports: [
    CommonModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,
    // UI Modules
    UIModule,
    // Material Components
    MatFormFieldModule,
    ...MaterialUIComponents,
    // iMask Module
    IMaskModule,
    SafePipeModule,
  ],
  exports: [FormsModule, ReactiveFormsModule, ...MaterialUIComponents, ...COMPONENTS],
  providers: [
    DynamicFormFieldService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'L',
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  declarations: [...COMPONENTS, DateMaskDirective],
})
export class DynamicFormFieldModule {}
