import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class S3PreSignedService {
  constructor(private http: HttpClient) {}

  uploadObject(signedUrl: string, file: File) {
    const headers = new HttpHeaders({
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${encodeURI(file.name)}"`,
    });

    return this.http
      .put(signedUrl, file, {
        headers: headers,
      })
      .pipe(map((res) => res));
  }
}
