export const gracePeriodMonthsNumber = () => {
  const customMask = {
    mask: [
      { mask: '' },
      {
        mask: 'num mes(es)',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ',',
            max: 12,
          },
        },
      },
    ],
  };

  return customMask;
};
