import { Expose, plainToClass, Transform } from 'class-transformer';
import { Customer, PrequalificationReport, Prospecting, Simulation } from '.';
import { Parameters } from './parameters.model';

export class Application {
  @Expose()
  uuid: string;

  @Expose()
  prospectingUuid: string;

  @Expose()
  date: Date;

  @Transform(({ value }) => plainToClass(PrequalificationReport, value), {
    toClassOnly: true,
  })
  preQualification: PrequalificationReport;

  simulation: Simulation;

  prospecting: Prospecting;

  @Transform(({ value }) => plainToClass(Customer, value), {
    toClassOnly: true,
  })
  customer: Customer;

  @Expose()
  property: any;

  @Expose()
  references: any;

  @Transform(({ value }) => plainToClass(Parameters, value), {
    toClassOnly: true,
  })
  parameters: Parameters;

  @Expose()
  statuses: Array<{
    code: string;
    name: string;
    color: string;
    date: Date;
  }>;

  @Expose()
  lastStatus: {
    code: string;
    name: string;
    color: string;
    date: Date;
  };

  @Expose()
  pep: {
    dataJson: string;
  };

  @Expose()
  disableMortgageAdvisorAssign: boolean;

  mortgageAdvisorsToAssign: Array<{
    label: string;
    email: string;
  }>;

  @Expose()
  isBlocked() {
    return this.lastStatus.code !== '100001' && this.lastStatus.code !== '100002';
  }
}
