import { Expose, Transform } from 'class-transformer';
import { maritalStatuses } from '../base';

export class PrequalificationListReport {
  uuid: string;

  result: string;

  prospect: {
    documentNumber: string;
    email: string;
    fathersLastName: string;
    givenName: string;
    middleName: string | null;
    mobilePhoneNumber: string;
    mothersLastName: string;
    maritalStatus: number;
  };

  employee: {
    fullName: string;
    email: string;
  };

  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  date: Date;

  @Expose()
  getFullName() {
    const names: string[] = [];
    if (this.prospect.givenName) names.push(this.prospect.givenName);
    if (this.prospect.middleName) names.push(this.prospect.middleName);
    if (this.prospect.fathersLastName) names.push(this.prospect.fathersLastName);
    if (this.prospect.mothersLastName) names.push(this.prospect.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getMaritalStatus() {
    return maritalStatuses[this.prospect.maritalStatus];
  }

  @Expose()
  getShortDate() {
    const month = ('0' + (this.date.getMonth() + 1)).slice(-2);
    const date = ('0' + this.date.getDate()).slice(-2);
    const year = this.date.getFullYear();

    return date + '/' + month + '/' + year;
  }
}
