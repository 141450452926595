import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vv-file',
  templateUrl: 'file.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileComponent),
      multi: true,
    },
  ],
})
export class FileComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() labelCss?: string;
  @Input() valid: boolean;
  @Input() previewSrc: string | ArrayBuffer | null;
  @Input() fileLabel: string;
  @Input() fileDetail: string;
  @Input() accept?: string = '.jpg, .png, .jpeg';
  value: File;

  @ViewChild('fileInput') fileInputElement: ElementRef;

  fileName = '';

  get isNotExcel() {
    if (!this.value) return true;

    return (
      this.value.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  constructor() {}

  onFileSelected(event) {
    if (event.target.files && event.target.files[0]) {
      const value = <File>event.target.files[0];
      this.writeValue(value);

      const reader = new FileReader();

      reader.onload = (e) => {
        if (this.valid) {
          this.previewSrc = reader.result;
        }
      };

      reader.readAsDataURL(value);
    }
  }

  writeValue(value: File): void {
    if (value === null) {
      this.fileInputElement.nativeElement.value = '';
    }
    this.value = value;
    this.onChangeCallback(value);
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback: any = () => {
    // do nothing.
  };

  onTouchedCallback: any = () => {
    // do nothing.
  };
}
