import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDialogService } from '@vivela/xplat/web/features';
import { AuthService } from '@vivela/xplat/core';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    public errorDialogService: ErrorDialogService,
    public authService: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const data: { title: string; content: string } = {
          title: 'Ocurrió un error',
          content: httpErrorResponse.error?.message || 'Inténtalo nuevamente',
        };

        const fileContentTypeHeader =
          httpErrorResponse.headers.get('Content-Type')?.includes('application/json') ||
          httpErrorResponse.headers.get('Content-Type')?.includes('application/pdf');

        if (fileContentTypeHeader && httpErrorResponse.error instanceof Blob) {
          const reader = new FileReader();
          reader.addEventListener('loadend', (e) => {
            try {
              const errorObj = JSON.parse(e.target?.result?.toString() || '');
              if (errorObj && errorObj['message']) {
                data.content = errorObj['message'];
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
          });
          reader.readAsText(httpErrorResponse.error);
        }

        if (httpErrorResponse.status == 403) {
          if (httpErrorResponse.error?.code === 'VB-1001') {
            this.authService.logout('/out-of-time');
            return throwError(() => httpErrorResponse);
          }
        }

        if (httpErrorResponse.status == 401) {
          this.authService.logout();
          return throwError(() => httpErrorResponse);
        }

        if (httpErrorResponse.status == 500) {
          data.content = 'No se pudo procesar la petición';
        }

        if (httpErrorResponse.status == 404 || httpErrorResponse.status == 422) {
          return throwError(() => httpErrorResponse);
        }

        if (httpErrorResponse.status == 400 && !!httpErrorResponse.error?.code) {
          return throwError(() => httpErrorResponse);
        }

        this.errorDialogService.openDialog(data);

        return throwError(() => httpErrorResponse);
      }),
    );
  }
}
