import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { ProspectingService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  isLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'prospecting',
})
@Injectable()
export class ProspectingState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  constructor(private prospectingService: ProspectingService) {
    super();
  }

  updateProspect(uuid, payload) {
    return this.prospectingService.updateProspect(uuid, payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getByUuid(uuid: string) {
    this.patchState(startLoader);

    return this.prospectingService.getByUuid(uuid).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  invalidate(uuid: string) {
    this.patchState(startLoader);

    return this.prospectingService.invalidate(uuid).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
