export interface PrequalificationRule {
  code: string;
  name: string;
  description: string;
  evaluatedPeriods: number;
  verdict: 'no-data' | 'approved' | 'not-approved';
  results: Array<{
    showDetails: boolean;
    profile: string;
    details: any;
    detailHeaders: any;
    detailFields: any;
  }>;
  ruleDetailsFields: any;
}
