// documentTypes
export const DOCUMENT_TYPE_DNI = 1;
export const DOCUMENT_TYPE_CE = 2;
export const DOCUMENT_TYPE_PASAPORTE = 3;
export const DOCUMENT_TYPE_CIM = 4;
export const DOCUMENT_TYPE_CPTP = 5;
export const DOCUMENT_TYPE_CI = 6;
export const DOCUMENT_TYPE_CR = 7;
export const DOCUMENT_TYPE_CEPR = 8;

export const documentTypes = {
  [DOCUMENT_TYPE_DNI]: 'DNI',
  [DOCUMENT_TYPE_CE]: 'Carné de extranjería',
  [DOCUMENT_TYPE_CIM]:
    'Carné de Identidad emitido por el Ministerio de Relaciones Exteriores',
  [DOCUMENT_TYPE_CPTP]: 'Carné de Permiso Temporal de Permanencia',
  [DOCUMENT_TYPE_CI]: 'Cédula de Identidad o documentos análogos',
  [DOCUMENT_TYPE_CR]: 'Carnet del refugiado',
  [DOCUMENT_TYPE_CEPR]:
    'Documento expedido por la CEPR del Ministerio de Relaciones Exteriores que acredita que la solicitud de refugiado se encuentra en trámite',
};

// maritalStatuses
export const MARITAL_STATUS_SINGLE = 1;
export const MARITAL_STATUS_MARRIED = 2;
export const MARITAL_STATUS_WIDOWER = 4;
export const MARITAL_STATUS_DIVORCED = 3;
export const MARITAL_STATUS_COHABITANT = 5;

export const maritalStatuses = {
  [MARITAL_STATUS_SINGLE]: 'Soltero',
  [MARITAL_STATUS_MARRIED]: 'Casado',
  [MARITAL_STATUS_WIDOWER]: 'Viudo',
  [MARITAL_STATUS_DIVORCED]: 'Divorciado',
  [MARITAL_STATUS_COHABITANT]: 'Conviviente',
};

// academicDegrees
export const ACADEMIC_DEGREE_PRIMARY = '1';
export const ACADEMIC_DEGREE_SECONDARY = '2';
export const ACADEMIC_DEGREE_TECHNICAL_CAREER = '3';
export const ACADEMIC_DEGREE_UNIVERSITY = '4';
export const ACADEMIC_DEGREE_MASTERS = '5';
export const ACADEMIC_DEGREE_NONE = '9';
export const academicDegrees = {
  [ACADEMIC_DEGREE_NONE]: 'Ninguno',
  [ACADEMIC_DEGREE_PRIMARY]: 'Primaria',
  [ACADEMIC_DEGREE_SECONDARY]: 'Secundaria',
  [ACADEMIC_DEGREE_TECHNICAL_CAREER]: 'Carrera técnica',
  [ACADEMIC_DEGREE_UNIVERSITY]: 'Universidad',
  [ACADEMIC_DEGREE_MASTERS]: 'Maestría',
};

// activityTypes
export const ACTIVITY_DEPENDENT = '000011';
export const ACTIVITY_INDEPENDENT = '000021';
export const ACTIVITY_MICRO_ENTREPRENEUR = '000031';
export const ACTIVITY_RETIRED = 'retired';
export const ACTIVITY_RENTIER = '000051';
export const ACTIVITY_OTROS = 'other';
export const ACTIVITY_NO_ACTIVITY = 'na';
export const ACTIVITY_NO_ACTIVITY_OLD = 'no-activity';

export const activityTypes = {
  [ACTIVITY_DEPENDENT]: 'Dependiente',
  [ACTIVITY_INDEPENDENT]: 'Independiente',
  [ACTIVITY_MICRO_ENTREPRENEUR]: 'Microempresario',
  //  [ACTIVITY_RETIRED]: 'Jubilado',
  [ACTIVITY_RENTIER]: 'Rentista',
  //  [ACTIVITY_OTROS]: 'Otros',
};

export const noActivityTypes = {
  [ACTIVITY_NO_ACTIVITY]: 'Sin actividad',
};

export const mainPreQualificationActivityTypes = {
  dependent: 'Dependiente',
  independent: 'Independiente',
  'micro-entrepreneur': 'Microempresario',
  rentier: 'Rentista',
};

export const secondaryPreQualificationActivityTypes = {
  dependent: 'Dependiente',
  independent: 'Independiente',
  'micro-entrepreneur': 'Microempresario',
  rentier: 'Rentista',
  [ACTIVITY_NO_ACTIVITY_OLD]: 'Sin actividad',
};

// Pep options
export const PEP_ACTUALMENTE = 'Actualmente';
export const PEP_ANTERIORMENTE = 'Anteriormente';
export const PEP_NUNCA = 'Nunca';

export const pep = {
  [PEP_ACTUALMENTE]: 'Sí, trabaja actualmente',
  [PEP_ANTERIORMENTE]: 'Sí, ha trabajado anteriormente',
  [PEP_NUNCA]: 'No, nunca',
};

// Pep relationship
export const RELATIONSHIP_ABUELO = 'Abuelo';
export const RELATIONSHIP_HERMANO = 'Hermano';
export const RELATIONSHIP_HIJO = 'Hijo';
export const RELATIONSHIP_NIETO = 'Nieto';
export const RELATIONSHIP_PADRE = 'Padre/Madre';

export const pepRelationship = {
  [RELATIONSHIP_ABUELO]: 'Abuelo/a',
  [RELATIONSHIP_HERMANO]: 'Hermano/a',
  [RELATIONSHIP_HIJO]: 'Hijo/a',
  [RELATIONSHIP_NIETO]: 'Nieto/a',
  [RELATIONSHIP_PADRE]: 'Padre/Madre',
};

// ReginemTypesreginemTypesRetired
export const REGIMEN_TYPE_RETIRED_ONP = 'ONP';
export const REGIMEN_TYPE_RETIRED_AFP = 'AFP';
export const REGIMEN_TYPE_RETIRED_POLICIAL = 'Policial';

export const reginemTypesRetired = {
  [REGIMEN_TYPE_RETIRED_ONP]: 'ONP',
  [REGIMEN_TYPE_RETIRED_AFP]: 'AFP',
  [REGIMEN_TYPE_RETIRED_POLICIAL]: 'Pensión policial militar',
};

// mortgageCompanies
export const MORTGAGE_COMPANY_MI_VIVIENDA = 'mi-vivienda';
export const MORTGAGE_COMPANY_TECHO_PROPIO = 'techo-propio';
export const MORTGAGE_COMPANY_BANCO_MATERIALES = 'banco-materiales';
export const MORTGAGE_COMPANY_FONAVI = 'fonavi';

export const mortgageCompanies = {
  [MORTGAGE_COMPANY_MI_VIVIENDA]: 'Mi Vivienda',
  [MORTGAGE_COMPANY_TECHO_PROPIO]: 'Techo Propio',
  [MORTGAGE_COMPANY_BANCO_MATERIALES]: 'Banco Materiales',
  [MORTGAGE_COMPANY_FONAVI]: 'Fonavi',
};

// propertyTypes
export const PROPERTY_TYPE_HOUSE = 'house';
export const PROPERTY_TYPE_DEPARTMENT = 'department';

export const propertyTypes = {
  [PROPERTY_TYPE_HOUSE]: 'Casa',
  [PROPERTY_TYPE_DEPARTMENT]: 'Departamento',
};

// propertyConditions
export const PROPERTY_CONDITION_NEW = 'new';
export const PROPERTY_CONDITION_IN_PROJECT = 'in-project';
export const PROPERTY_CONDITION_USED = 'used';

export const propertyConditions = {
  [PROPERTY_CONDITION_NEW]: 'Estreno',
  [PROPERTY_CONDITION_IN_PROJECT]: 'En proyecto',
  [PROPERTY_CONDITION_USED]: 'Usado',
};

// insurances
export const insurances = {
  s: 'Simple',
  m: 'Mancomunado',
};

// projects
export const projects = {
  H: 'Huarango',
  cs: 'Ciudad Sol, El Retablo',
};

// Etapas
export const etapas = {
  '1': '1',
  '2': '2',
  '3': '3',
};

// ReferenceRelation
export const referenceRelations = {
  '000005': 'Abuelo (a)',
  '000007': 'Amigo (a)',
  '000002': 'Hermano (a)',
  '000006': 'Nieto (a)',
  '000001': 'Padre / Madre',
  '000004': 'Sobrino (a)',
  '000003': 'Tío (a)',
};

// statementModality
export const STATEMENT_MODALITY_VIRTUAL = '000001';
export const STATEMENT_MODALITY_FISICO = '000002';
export const statementModalities = {
  [STATEMENT_MODALITY_FISICO]: 'Físico (domicilio del titular del crédito)',
  [STATEMENT_MODALITY_VIRTUAL]: 'Virtual (email del titular del crédito)',
};

export const financialEntities = {
  '000099': '<< OTROS >>',
  '000032': 'AGROBANCO',
  '000011': 'BANCO BIF',
  '000013': 'BANCO CITIBANK',
  '000008': 'BANCO DE COMERCIO',
  '000001': 'BANCO DE CREDITO',
  '000012': 'BANCO DE LA NACION',
  '000005': 'BANCO FALABELLA',
  '000034': 'BANCO GNB PERU',
  '000003': 'BANCO INTERBANK',
  '000015': 'BANCO MIBANCO',
  '000009': 'BANCO PICHINCHA',
  '000006': 'BANCO RIPLEY',
  '000007': 'BANCO SCOTIABANK',
  '000002': 'BBVA BANCO CONTINENTAL',
  '000010': 'CAJA METROPOLITANA DE LIMA',
  '000033': 'CAJA MUNICIPAL DE AREQUIPA',
  '000018': 'CAJA MUNICIPAL DE HUANCAYO',
  '000022': 'CAJA MUNICIPAL DE ICA',
  '000026': 'CAJA MUNICIPAL DE MAYNAS',
  '000023': 'CAJA MUNICIPAL DE PAITA',
  '000016': 'CAJA MUNICIPAL DE TRUJILLO',
  '000024': 'CAJA RURAL CREDINKA',
  '000025': 'CAJA RURAL LOS ANDES',
  '000031': 'CAJA RURAL PROFINANZAS',
  '000030': 'CAJA RURAL PRYMERA',
  '000020': 'CAJA RURAL SEÑOR DE LUREN',
  '000019': 'FINANCIERA CONFIANZA',
  '000035': 'FINANCIERA CREDINKA',
  '000004': 'FINANCIERA CREDISCOTIA',
  '000017': 'FINANCIERA EDYFICAR',
  '000021': 'FINANCIERA TFC',
};
