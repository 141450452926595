import { Injectable } from '@angular/core';
import { Customer, EconomicActivity } from '../../models';
import { plainToClass } from 'class-transformer';

import { map } from 'rxjs/operators';
import { DataService } from '../data.service';

@Injectable()
export class CustomerService {
  constructor(private dataService: DataService) {}

  updateCustomer(uuid: string, payload: any) {
    return this.dataService
      .patch<Customer>(`customers/${uuid}`, payload)
      .pipe(map((res) => plainToClass(Customer, res.data)));
  }

  addEconomicActivity(payload: any) {
    return this.dataService
      .post<EconomicActivity>(`economic-activities`, payload)
      .pipe(map((res) => plainToClass(EconomicActivity, res.data)));
  }

  updateEconomicActivity(economicActivityUuid: string, payload: any) {
    return this.dataService
      .patch<EconomicActivity>(`economic-activities/${economicActivityUuid}`, payload)
      .pipe(map((res) => plainToClass(EconomicActivity, res.data)));
  }

  getEconomicActivities(customerUuid: string) {
    return this.dataService
      .get<{ principalActivity: any; activities: EconomicActivity[] }>(
        `economic-activities/get-by-customer/${customerUuid}`,
      )
      .pipe(
        map((res) => {
          const activities = plainToClass(EconomicActivity, res.data.activities);
          return {
            principalActivity: res.data.principalActivity,
            activities,
          };
        }),
      );
  }

  removeEconomicActivity(uuid: string) {
    return this.dataService
      .delete(`economic-activities/${uuid}`)
      .pipe(map((res) => res.data));
  }
}
