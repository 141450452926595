import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vv-paginator',
  templateUrl: 'paginator.component.html',
})
export class PaginatorComponent extends BaseComponent {
  @Input() items = [];
  @Input() interval = 10;
  @Input() pages = 3;
  @Output() pageChange = new EventEmitter<unknown[]>(true);

  maxPages: number;
  currentPage = 1;
  paginatorPages: number[];

  ngOnInit() {
    this.maxPages = Math.ceil(this.items.length / this.interval);
    if (this.maxPages < this.pages) {
      this.pages = this.maxPages;
    }
    this.reloadItems();
  }

  onClickPrev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.reloadItems();
    }
  }

  onClickNext() {
    if (this.currentPage < this.maxPages) {
      this.currentPage++;
      this.reloadItems();
    }
  }

  onClickPage(page: number) {
    this.currentPage = page;
    this.reloadItems();
  }

  private reloadItems(): void {
    const items = this.items.slice(
      (this.currentPage - 1) * this.interval,
      this.currentPage * this.interval,
    );
    this.reloadPages();

    this.pageChange.emit(items);
  }

  private reloadPages(): void {
    const diff = Math.floor(this.pages / 2);

    if (this.currentPage <= diff) {
      this.paginatorPages = Array(this.pages)
        .fill(0)
        .map((x, i) => i + 1);
      return;
    }

    let i = -diff;
    for (const p in this.paginatorPages) {
      if (this.currentPage > diff && this.currentPage <= this.maxPages - diff) {
        this.paginatorPages[p] = i + this.currentPage;
        i++;
      }
    }
  }
}
