import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';

type StateModel = {
  jwt: string;
};

@StateRepository()
@State<StateModel>({
  name: 'user',
})
@Injectable()
export class UserState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isAuthenticated() {
    return !!this.snapshot.jwt;
  }
}
