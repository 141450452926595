import { Component } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vivela-backoffice-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent extends BaseComponent {
  menuItems = [
    {
      text: 'Dashboard',
      url: '/dashboard',
    },
    {
      text: 'Evaluaciones',
      url: '/prequalifications',
    },
    {
      text: 'Administración',
      url: '/admin',
    },
  ];
}
