import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '..';

import { FormFieldBase } from './models';
import { fromEvent, Observable, Subscription, takeUntil } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { OptionType } from './types';
import { BaseComponent, getConstAsSelectOptions } from '@vivela/xplat/core';
import { MatDatepickerInput } from '@angular/material/datepicker';

@Component({
  selector: 'vv-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
})
export class DynamicFormFieldComponent extends BaseComponent implements AfterViewInit {
  @Input() formField: FormFieldBase<string | string[] | number | boolean | Date>;
  @Input() form: UntypedFormGroup;
  @Input() disabled?: boolean;
  @Input() onKeydownEnter?: () => void;

  autocompleteControl = new UntypedFormControl();
  filteredOptions: Observable<OptionType[]>;
  hidePassword = true;

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef<HTMLInputElement>;

  eventSubscription: Subscription;

  @ViewChild('datepickerInput')
  datepickerInput: ElementRef;

  @ViewChild(MatDatepickerInput)
  matDatepickerInput: MatDatepickerInput<any>;

  ngAfterViewInit() {
    if (this.formField.controlType === 'datepicker') {
      this.eventSubscription = fromEvent(this.datepickerInput.nativeElement, 'input')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.matDatepickerInput._onInput(this.datepickerInput.nativeElement.value);
        });
    }
  }

  constructor() {
    super();
    this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
      startWith(null),
      map((e: string | null) =>
        e ? this._filter(e) : (this.formField.options as OptionType[]),
      ),
    );
  }

  ngOnInit() {
    this.autocompleteControl.setValidators(this.formField.validators);
    this.autocompleteControl.setValue(this.formControl.value);
    if (this.disabled) {
      this.autocompleteControl.disable();
    }
    if (
      this.formField.controlType == 'autocomplete' ||
      this.formField.controlType == 'chips-autocomplete'
    ) {
      const originalMethod = this.formControl.markAsTouched;
      this.formControl.markAsTouched = (args) => {
        originalMethod.apply(this, args);
        this.autocompleteControl.markAsTouched(args);
        if (this.formControl.errors) {
          this.autocompleteControl.setErrors(this.formControl.errors);
        }
      };
    }
  }

  get formControl(): AbstractControl {
    return this.form.controls[this.formField.key];
  }

  get isInvalid(): boolean {
    return this.formControl.invalid && this.formControl.touched;
  }

  getLabelByValue(value: string): string {
    const options = this.formField.options as OptionType[];

    const [option] = options.filter((e) => value == e.value);

    return option.label;
  }

  setFormControlValue(value: string | string[]) {
    this.formControl.setValue(value);
  }

  updateFormControlValueAfterClosePanel() {
    const options = this.formField.options as OptionType[];
    const found = options.find((option) => {
      return option.value === this.autocompleteControl.value;
    });
    if (found) {
      this.formControl.setValue(this.autocompleteControl.value);
    } else {
      this.formControl.setValue('');
    }
  }

  addFormControlValue(value: string | string[], reset = true) {
    const currentValues = this.getFormControlValue() || [];
    if (!currentValues.includes(value)) {
      this.formControl.setValue([...currentValues, value]);
    }
    if (reset) {
      this.autocompleteInput.nativeElement.value = '';
      this.autocompleteControl.setValue('');
    }
  }

  removeFormControlValue(value: string) {
    const currentValues = this.getFormControlValue() || [];
    const newValues = currentValues.filter((e) => e != value);
    this.formControl.setValue(newValues);
  }

  getFormControlValue() {
    return this.formControl.value;
  }

  getDynamicOptionsByFormValue(
    key: string,
    func: (arg: string) => SelectOption[],
  ): SelectOption[] {
    const value = this.form.controls[key].value;

    return func(value);
  }

  autocompleteClicked(formField: FormFieldBase<string | string[] | number | boolean>) {
    if (formField.options.length === 0) {
      formField.isLoading = true;
      formField.loadOptionsFail = false;
      this.subscribe(
        formField.optionsLoadMethod.pipe(
          tap(
            (res) => {
              formField.options = getConstAsSelectOptions(res);
            },
            (error) => {
              this.autocompleteInput.nativeElement.value = '';
              this.autocompleteControl.setValue('');
              formField.loadOptionsFail = true;
              formField.isLoading = false;
            },
            () => {
              formField.isLoading = false;
            },
          ),
        ),
      );
    }
  }

  private _filter(value: string): OptionType[] {
    const filterValue = value.toLowerCase();
    const options = this.formField.options as OptionType[];
    let optionsFiltered = options.filter((e) =>
      e.label.toLowerCase().includes(filterValue),
    );

    if (optionsFiltered.length === 0) {
      optionsFiltered = options.filter((e) =>
        e.value.toLowerCase().includes(filterValue),
      );
    }

    return optionsFiltered;
  }

  displayFn(value?: string) {
    const options = this.formField.options as OptionType[];
    if (value) {
      const option = options.filter((_) => _.value === value);
      if (option[0]) {
        return option[0].label;
      }
    }
    return undefined;
  }
}
