/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vv-textfield',
  templateUrl: 'textfield.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextfieldComponent),
      multi: true,
    },
  ],
})
export class TextfieldComponent extends BaseComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() type: 'text' | 'email' | 'number';
  @Input() validate: boolean;
  @Input() disable: boolean;
  @Input() min: number;
  @Input() step: number;

  value: string | number;

  constructor() {
    super();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback: any = () => {
    // do nothing.
  };

  onTouchedCallback: any = () => {
    // do nothing.
  };

  //Set touched on blur
  onTouched() {
    this.onTouchedCallback(null);
  }

  onChange(newValue: any) {
    this.value = newValue;
    this.onChangeCallback(this.value);
  }
}
