import { Expose, plainToClass, Transform } from 'class-transformer';
import { CustomerLegacy } from './customer-legacy.model';

export class Customer {
  @Expose()
  uuid: string;

  @Expose()
  documentType: { code: number };

  @Expose()
  documentNumber: string;

  @Expose()
  givenName: string;

  @Expose()
  middleName: string | null;

  @Expose()
  fathersLastName: string;

  @Expose()
  mothersLastName: string;

  @Expose()
  marriedName?: string;

  @Expose()
  mobilePhoneNumber: string;

  @Expose()
  email: string;

  @Expose()
  maritalStatus: { code: number };

  @Expose()
  academicDegree: { code: string };

  @Expose()
  marketingAllowedAt: Date;

  @Expose()
  pollsAllowedAt: Date;

  @Expose()
  transferInformationAllowedAt: Date;

  @Transform(({ value }) => plainToClass(Customer, value), {
    toClassOnly: true,
  })
  spouse?: Customer;

  @Expose()
  birthDate: Date;

  @Transform(({ value }) => plainToClass(CustomerLegacy, value), {
    toClassOnly: true,
  })
  customerLegacy?: CustomerLegacy;

  @Expose()
  getGivenAndMiddleName() {
    const names: string[] = [];
    if (this.givenName) names.push(this.givenName);
    if (this.middleName) names.push(this.middleName);

    return names.join(' ');
  }

  @Expose()
  hasGivenAndMiddleName(): boolean {
    if (this.givenName) return true;
    if (this.middleName) return true;

    return false;
  }

  @Expose()
  hasFathersLastName(): boolean {
    return !!this.fathersLastName;
  }

  @Expose()
  hasMothersLastName(): boolean {
    return !!this.mothersLastName;
  }

  @Expose()
  getFullName() {
    const names: string[] = [];
    if (this.givenName) names.push(this.givenName);
    if (this.middleName) names.push(this.middleName);
    if (this.fathersLastName) names.push(this.fathersLastName);
    if (this.mothersLastName) names.push(this.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  isMarried() {
    return this.maritalStatus.code === 2;
  }

  @Expose()
  isWidower() {
    return this.maritalStatus.code === 4;
  }

  @Expose()
  hasDocumentTypeCE() {
    return this.documentType.code === 2;
  }

  @Expose()
  hasForeignCountryOfResidence() {
    if (!this.customerLegacy?.countryOfResidence) {
      return false;
    }
    return this.customerLegacy?.countryOfResidence !== '004028';
  }

  @Expose()
  hasBirthDate() {
    return !!this.birthDate;
  }
}
