import { Expose, plainToClass, Transform } from 'class-transformer';

export class CustomerLegacy {
  @Expose()
  id: number;

  @Expose()
  sex: string;

  @Expose()
  countryOfBirth: string;

  @Expose()
  countryOfResidence: string;

  @Expose()
  matrimonialRegime: string;

  @Expose()
  profession: string;

  @Expose()
  address: string;

  @Expose()
  addressReference: string;

  @Expose()
  department: string;

  @Expose()
  province: string;

  @Expose()
  district: string;

  @Expose()
  foreignAddress: string;

  @Expose()
  foreignCity: string;

  @Expose()
  foreignZipCode: string;
}
