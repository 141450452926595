import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@vivela/xplat/core';

@Directive({
  selector: '[vivelaCheckRole]',
})
export class CheckRoleDirective implements OnInit {
  private roles: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}

  @Input()
  set vivelaCheckRole(value: string | string[]) {
    if (Array.isArray(value)) {
      this.roles = value;
    } else {
      this.roles = [value];
    }
  }

  ngOnInit(): void {
    this.updateView();
  }

  private updateView() {
    if (this.checkRoles()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkRoles() {
    if (this.roles.includes('*')) return true;

    let hasRole = false;

    const currentUserRoles = this.authService.getUserRoles();

    if (currentUserRoles.length > 0) {
      for (const role of this.roles) {
        hasRole = currentUserRoles.includes(role);
        if (hasRole) break;
      }
    }

    return hasRole;
  }
}
