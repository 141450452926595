/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SelectBaseComponent } from '@vivela/xplat/features';

@Component({
  selector: 'vv-select',
  templateUrl: 'select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends SelectBaseComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() validate: boolean;
  @Input() disable: boolean;

  optionSelected = '';

  constructor() {
    super();
  }

  writeValue(obj: string): void {
    this.optionSelected = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback: any = () => {
    // do nothing.
  };

  onTouchedCallback: any = () => {
    // do nothing.
  };

  //Set touched on blur
  onTouched() {
    this.onTouchedCallback(null);
  }

  onChange(newValue: string) {
    this.optionSelected = newValue;
    this.onChangeCallback(newValue);
  }
}
