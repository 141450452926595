<div *ngIf="show" class="modal" [class]="size ? 'modal--' + size : ''" @modalAnimation>
  <div class="modal-backdrop modal-dark-backdrop modal-backdrop-showing"></div>
  <div class="modal__content">
    <div class="modal__close" [ngClass]="!showCloseButton ? 'my-30' : ''">
      <vv-button
        *ngIf="showCloseButton"
        buttonIcon
        icon="close"
        color="light"
        (click)="close()"
      ></vv-button>
    </div>
    <div class="modal__body">
      <ng-content select="[body]"></ng-content>
      <vv-button *ngIf="closeButtonByDefault" color="primary" (click)="close()"
        >SALIR</vv-button
      >
    </div>
  </div>
</div>
