import { PrequalificationReportRule } from './';

export interface PrequalificationReportMandatoryVariables {
  rules: PrequalificationReportRule[];
}

export interface PrequalificationReportLevelOneVariables {
  rules: PrequalificationReportRule[];
}

export interface PrequalificationReportExternalVariables {
  rules: PrequalificationReportRule[];
}
