import { Component } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vv-card',
  templateUrl: 'card.component.html',
})
export class CardComponent extends BaseComponent {
  constructor() {
    super();
  }
}
