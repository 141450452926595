import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { map } from 'rxjs/operators';
import {
  DispatchSingleModel,
  LetterCustomerModel,
  LetterListModel,
  LetterModel,
  LetterSingleModel,
} from '../../models';
import { plainToInstance } from 'class-transformer';

@Injectable()
export class LetterService {
  constructor(private dataService: DataService) {}

  list(previousCursor?: string, nextCursor?: string) {
    const params = {};
    if (previousCursor) {
      params['previousCursor'] = previousCursor;
    }

    if (nextCursor) {
      params['nextCursor'] = nextCursor;
    }

    return this.dataService
      .get<LetterListModel>('legal/letter/list', {
        params: params,
      })
      .pipe(map((res) => plainToInstance(LetterListModel, res.data)));
  }

  create(name: string, confirm?: boolean) {
    return this.dataService
      .post<LetterSingleModel>('legal/letter', { name, confirm })
      .pipe(map((res) => plainToInstance(LetterSingleModel, res.data)));
  }

  detail(letterUuid: string) {
    return this.dataService
      .get<LetterModel>(`legal/letter/detail/${letterUuid}`)
      .pipe(map((res) => plainToInstance(LetterModel, res.data)));
  }

  addCustomer(payload: any) {
    return this.dataService
      .post<LetterCustomerModel>(`legal/letter/add-customer`, payload)
      .pipe(map((res) => plainToInstance(LetterCustomerModel, res.data)));
  }

  removeCustomer(payload: any) {
    return this.dataService
      .post<boolean>(`legal/letter/remove-customer`, payload)
      .pipe(map((res) => res.data));
  }

  sendCustomersToBlacklist(letterUuid: string) {
    return this.dataService
      .put<LetterSingleModel>(`legal/letter/send-customers-to-blacklist/${letterUuid}`)
      .pipe(map((res) => plainToInstance(LetterSingleModel, res.data)));
  }

  dispatchLetter(letterUuid: string, payload?: any) {
    return this.dataService
      .post<LetterSingleModel>(`legal/letter/${letterUuid}/dispatch`, payload)
      .pipe(map((res) => plainToInstance(LetterSingleModel, res.data)));
  }

  saveLetterDispatchResponse(letterUuid: string, payload: { recipient: string }) {
    return this.dataService
      .post<DispatchSingleModel>(`legal/letter/${letterUuid}/dispatch-response`, payload)
      .pipe(map((res) => plainToInstance(DispatchSingleModel, res.data)));
  }

  downloadDispatchResponseFile(letterUuid: string) {
    return this.dataService.getBlob(
      `legal/letter/${letterUuid}/dispatch-response/download`,
    );
  }

  getGovernmentEmails() {
    return this.dataService
      .get<string[]>(`legal/government/emails`)
      .pipe(map((res) => res.data));
  }
}
