import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { map } from 'rxjs/operators';

import {
  ConsolidatedPersonModel,
  PrequalificationListReport,
  PrequalificationPreFilterResult,
  PrequalificationReport,
  PrequalificationReportUUID,
  PrequalificationParseFromExcelModel,
  PrequalificationCreatedFromExcelModel,
} from '../../models';
import { DataService } from '../data.service';
import {
  CreatePrequalificationReportPayload,
  PreFilterPrequalificationPersonsPayload,
} from './pre-qualification.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class PreQualificationService {
  constructor(private dataService: DataService) {}

  preFilterPrequalificationPersons(payload: PreFilterPrequalificationPersonsPayload) {
    return this.dataService
      .post<PrequalificationPreFilterResult>(
        'prospecting/validations/verify-documents',
        payload,
      )
      .pipe(map((res) => res.data));
  }

  createPrequalificationReport(payload: CreatePrequalificationReportPayload) {
    return this.dataService
      .post<PrequalificationReportUUID>('v2/prospecting/pre-qualifications', payload)
      .pipe(map((res) => res.data));
  }

  getPrequalificationReportById(id: string) {
    return this.dataService
      .get<PrequalificationReport>(`v2/prospecting/pre-qualifications/${id}`)
      .pipe(map((res) => plainToClass(PrequalificationReport, res.data)));
  }

  getPrequalificationReports() {
    return this.dataService
      .get<{ items: PrequalificationListReport[] }>('prospecting/pre-qualifications')
      .pipe(
        map((res) => {
          const items = plainToClass(PrequalificationListReport, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getPreviousPrequalificationReports(cursor: string) {
    return this.dataService
      .get<{ items: PrequalificationListReport[] }>(
        'prospecting/pre-qualifications?previousCursor=' + cursor,
      )
      .pipe(
        map((res) => {
          const items = plainToClass(PrequalificationListReport, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getNextPrequalificationReports(cursor: string) {
    return this.dataService
      .get<{ items: PrequalificationListReport[] }>(
        'prospecting/pre-qualifications?nextCursor=' + cursor,
      )
      .pipe(
        map((res) => {
          const items = plainToClass(PrequalificationListReport, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getPrequalificationReportsByDocumentNumber(documentNumber: string) {
    return this.dataService
      .get<{ items: PrequalificationListReport[] }>(
        'prospecting/pre-qualifications/get-by-documentNumber?documentNumber=' +
          documentNumber,
      )
      .pipe(
        map((res) => {
          const items = plainToClass(PrequalificationListReport, res.data.items);
          return { ...res.data, items };
        }),
      );
  }

  getSimulationIdByPrequalificationId(id: string) {
    return this.dataService
      .post('emi-calculator-states/verify', { preQualificationUuid: id })
      .pipe(map((res) => res.data));
  }

  getPrequalificationReportByProspecting(uuid: string) {
    return this.dataService
      .get(`prospecting/pre-qualifications/get-by-prospecting/${uuid}`)
      .pipe(map((res) => res.data));
  }

  getSimulationConfig() {
    return this.dataService.getTemp('configurations').pipe(map((res: any) => res.data));
  }

  getPersonInformation(documentType: string, documentNumber: string) {
    return this.dataService
      .get<ConsolidatedPersonModel>('autocomplete/person', {
        params: {
          documentType,
          documentNumber,
        },
      })
      .pipe(map((res) => res.data));
  }

  updatePrequalificationReport(uuid: string) {
    return this.dataService
      .patch<PrequalificationReport>(
        'v2/prospecting/pre-qualifications/force-update-report',
        { uuid },
      )
      .pipe(map((res) => plainToClass(PrequalificationReport, res.data)));
  }

  parsePrequalificationFromExcel(payload) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this.dataService
      .post<PrequalificationParseFromExcelModel>(
        'prospecting/pre-qualifications/excel',
        payload,
        { headers },
      )
      .pipe(map((res) => res.data));
  }

  importPrequalificationFromExcel(preQualifications) {
    return this.dataService
      .post<PrequalificationCreatedFromExcelModel[]>(
        'v2/prospecting/pre-qualifications/import',
        { preQualifications },
      )
      .pipe(map((res) => res.data));
  }
}
