<button [class]="class">
  <vv-icon *ngIf="isButtonIcon; else text" [icon]="icon" size="sm"></vv-icon>
  <ng-template #text>
    <ng-content></ng-content>
    <vv-icon
      *ngIf="isButtonTextIcon"
      [icon]="icon"
      size="sm"
      class="margin-left-10"
    ></vv-icon>
  </ng-template>
</button>
