import { NgModule, LOCALE_ID } from '@angular/core';
import LocaleEsPe from '@angular/common/locales/es-PE';
import { registerLocaleData } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
// app
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { CurrencyPipe } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { VivelaMatPaginatorIntl } from '@vivela/xplat/web/features';
import { environment } from '@vivela/xplat/environment';

registerLocaleData(LocaleEsPe);

@NgModule({
  imports: [CoreModule, SharedModule, AppRoutingModule, RecaptchaV3Module],
  declarations: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-PE' },
    { provide: MatPaginatorIntl, useClass: VivelaMatPaginatorIntl },
    { provide: 'googleTagManagerId', useValue: 'GTM-59QXFB6' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
