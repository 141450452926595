import { Expose, Transform } from 'class-transformer';
import { Parameter } from '@vivela/xplat/api';
import { SelectOption } from '@vivela/xplat/web/features';

enum ParameterKey {
  TIPOS_DE_DOCUMENTOS = 'TIPOS DE DOCUMENTOS',
  SEXO = 'SEXO',
  REGIMEN_CONYUGAL = 'REGIMEN CONYUGAL',
  ESTADO_CIVIL = 'ESTADO CIVIL',
  UBICACIONES_GEOGRAFICAS = 'UBICACIONES GEOGRAFICAS',
  PROFESIONES_Y_OFICIOS = 'PROFESIONES Y OFICIOS',
  PAISES = 'PAISES',
  FOREIGN_CITIES = 'FOREIGN_CITIES',
}

enum UbigeoOption {
  DEPARTMENT = 'DEPARTMENT',
  PROVINCE = 'PROVINCE',
  DISTRICT = 'DISTRICT',
}

type ApiOption = {
  descri: string;
  codIte: string;
};

export class Parameters {
  [ParameterKey.TIPOS_DE_DOCUMENTOS]: Parameter[];
  [ParameterKey.SEXO]: Parameter[];
  [ParameterKey.REGIMEN_CONYUGAL]: Parameter[];
  [ParameterKey.ESTADO_CIVIL]: Parameter[];
  [ParameterKey.PROFESIONES_Y_OFICIOS]: Parameter[];

  @Transform(({ value }) => {
    const ubigeo: { [key in UbigeoOption]: any[] } = {
      DEPARTMENT: [],
      PROVINCE: [],
      DISTRICT: [],
    };
    let department = '';
    let province = '';
    for (const item of value) {
      if (item.codIte.substring(2) === '0000') {
        (department = item.codIte),
          ubigeo.DEPARTMENT.push({
            value: item.codIte,
            label: item.descri[0].toUpperCase() + item.descri.slice(1).toLowerCase(),
          });
      } else if (item.codIte.substring(4) === '00') {
        (province = item.codIte),
          ubigeo.PROVINCE.push({
            value: item.codIte,
            label: item.descri[0].toUpperCase() + item.descri.slice(1).toLowerCase(),
            departmentId: department,
          });
      } else {
        ubigeo.DISTRICT.push({
          value: item.codIte,
          label: item.descri[0].toUpperCase() + item.descri.slice(1).toLowerCase(),
          departmentId: department,
          provinceId: province,
        });
      }
    }

    return ubigeo;
  })
  [ParameterKey.UBICACIONES_GEOGRAFICAS]: { [key in UbigeoOption]: any[] };

  @Transform(({ value }) => {
    const ubigeo: any[] = [];
    for (const item of value) {
      ubigeo.push({
        value: item.cityCode,
        label: item.name[0].toUpperCase() + item.name.slice(1).toLowerCase(),
        countryCode: item.countryCode,
      });
    }

    return ubigeo;
  })
  [ParameterKey.FOREIGN_CITIES]: any[];

  @Expose()
  getDepartmentOptions(): Array<{ value: string; label: string }> {
    return this[ParameterKey.UBICACIONES_GEOGRAFICAS].DEPARTMENT;
  }

  @Expose()
  getProvinceOptions(departamentId: string): Array<{ value: string; label: string }> {
    return this.sortOptions(
      this[ParameterKey.UBICACIONES_GEOGRAFICAS].PROVINCE.filter(
        (province) => province.departmentId == departamentId,
      ),
    );
  }

  @Expose()
  getDistrictOptions(provinceId: string): Array<{ value: string; label: string }> {
    return this.sortOptions(
      this[ParameterKey.UBICACIONES_GEOGRAFICAS].DISTRICT.filter(
        (district) => district.provinceId == provinceId,
      ),
    );
  }

  @Expose()
  getCountryOfResidenceOptions(): Array<{ value: string; label: string }> {
    const foreignCountries = {};
    this[ParameterKey.FOREIGN_CITIES].forEach((city) => {
      foreignCountries[city.countryCode] = true;
    });

    const options = this[ParameterKey.PAISES].map((option: ApiOption) => {
      return {
        label: option.descri,
        value: option.codIte,
      };
    });
    const countries = options.filter((country) => foreignCountries[country.value]);

    countries.push({
      value: '004028',
      label: 'PERU',
    });
    return this.sortOptions(countries);
  }

  @Expose()
  getForeignCityOptions(countryId: string): Array<{ value: string; label: string }> {
    return this.sortOptions(
      this[ParameterKey.FOREIGN_CITIES].filter((city) => city.countryCode == countryId),
    );
  }

  @Expose()
  getDocumentOptions(): Array<{ value: string; label: string }> {
    return this[ParameterKey.TIPOS_DE_DOCUMENTOS].map((parameter) => {
      return {
        value: parameter.codGrp + '#' + parameter.codIte,
        label:
          parameter.descri[0].toUpperCase() + parameter.descri.slice(1).toLowerCase(),
      };
    });
  }

  @Expose()
  getSexOptions(): Array<{ value: string; label: string }> {
    return this.getOptions(ParameterKey.SEXO);
  }

  @Expose()
  getCountryOfBirthOptions(): Array<{ value: string; label: string }> {
    return this[ParameterKey.PAISES].map((option: ApiOption) => {
      return {
        label: option.descri,
        value: option.codIte,
      };
    });
  }

  @Expose()
  getCivilStatusOptions(): Array<{ value: string; label: string }> {
    return this[ParameterKey.ESTADO_CIVIL].map((parameter) => {
      return {
        value: parameter.codGrp + '#' + parameter.codIte,
        label:
          parameter.descri[0].toUpperCase() + parameter.descri.slice(1).toLowerCase(),
      };
    });
  }

  @Expose()
  getMatrimonialRegimeOptions(): Array<{ value: string; label: string }> {
    return this.getOptions(ParameterKey.REGIMEN_CONYUGAL);
  }

  @Expose()
  getProfessionOptions(): Array<{ value: string; label: string }> {
    return this.getOptions(ParameterKey.PROFESIONES_Y_OFICIOS);
  }

  private getOptions(key): SelectOption[] {
    type ApiOption = {
      descri: string;
      codIte: string;
    };

    return this[key].map((option: ApiOption) => {
      return {
        label: option.descri[0].toUpperCase() + option.descri.slice(1).toLowerCase(),
        value: option.codIte,
      };
    });
  }

  private sortOptions(options): Array<{ value: string; label: string }> {
    return options.sort((a, b) => a.label.localeCompare(b.label));
  }
}
