<div class="switch">
  <input
    type="checkbox"
    id="switch{{ fieldName }}"
    class="switch__btn {{ color }}"
    (click)="onClick()"
    [checked]="checked"
    [disabled]="disable"
  />
  <label for="switch{{ fieldName }}"></label>

  <label for="switch{{ fieldName }}" class="switch__label">{{ label }}</label>
</div>
