import { AuthErrorComponent } from './auth-error/auth-error.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OutOfTimeComponent } from './out-of-time/out-of-time.component';

export const SHARED_COMPONENTS = [
  HeaderComponent,
  SidebarComponent,
  AuthErrorComponent,
  OutOfTimeComponent,
];

export * from './header/header.component';
export * from './sidebar/sidebar.component';
export * from './auth-error/auth-error.component';
export * from './out-of-time/out-of-time.component';
