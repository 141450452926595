export class SessionManager {
  static setItem = (key: string, value: string) => {
    return localStorage.setItem(key, value);
  };

  static getItem = (key: string) => {
    return localStorage.getItem(key);
  };

  static removeItem = (key: string) => {
    return localStorage.removeItem(key);
  };

  static clear = () => {
    return localStorage.clear();
  };
}
