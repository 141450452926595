import { Expose } from 'class-transformer';

export class Rate {
  @Expose()
  description: string;

  @Expose()
  rate: number;

  @Expose()
  minRate: number;
}
