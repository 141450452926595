import { Expose } from 'class-transformer';

export class User {
  @Expose()
  uuid: string;

  @Expose()
  givenName: string;

  @Expose()
  middleName: string;

  @Expose()
  fathersLastName: string;

  @Expose()
  mothersLastName: string;

  @Expose()
  documentTypeCode: string;

  @Expose()
  documentNumber: string;

  @Expose()
  mobilePhoneNumber: string;

  @Expose()
  workPhoneNumber: string;

  @Expose()
  email: string;

  @Expose()
  workEmail: string;

  @Expose()
  photo: string;

  @Expose()
  roles: { names: string[] };

  @Expose()
  enabled: boolean;

  @Expose()
  maritalStatus: string;

  @Expose()
  getFullName() {
    const names: string[] = [];
    if (this.givenName) names.push(this.givenName);
    if (this.middleName) names.push(this.middleName);
    if (this.fathersLastName) names.push(this.fathersLastName);
    if (this.mothersLastName) names.push(this.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getDocument() {
    return {
      document_type: `${this.documentTypeCode}`,
      document_number: this.documentNumber,
    };
  }
}
