import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from '@vivela/xplat/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private zone: NgZone,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const idToken = this.authService.idToken;
    const isLoggedIn = !!idToken;
    const isApiUrl = request.url.startsWith(environment.baseUrl);
    if (isLoggedIn && isApiUrl) {
      if (request.headers.has('enctype')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${idToken}`,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
    }

    return next.handle(request).pipe(
      map((response) => {
        if (response instanceof HttpResponse && response.status == 401) {
          this.zone.run(() => this.router.navigate(['/login']));
        }
        return response;
      }),
    );
  }
}
