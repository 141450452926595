import { Expose, Transform } from 'class-transformer';

export class PrequalificationListReport {
  @Expose({ name: 'pre_qualification_uuid' })
  uuid: string;

  @Expose({ name: 'pre_qualification_result' })
  result: string;

  @Expose({ name: 'prospect_given_name' })
  givenName: string;

  @Expose({ name: 'prospect_middle_name' })
  middledName: string;

  @Expose({ name: 'prospect_fathers_last_name' })
  fathersLastName: string;

  @Expose({ name: 'prospect_mothers_last_name' })
  mothersLastName: string;

  @Expose({ name: 'prospect_mobile_phone_number' })
  phoneNumber: string;

  @Expose({ name: 'prospect_document_number' })
  documentNumber: string;

  @Expose({ name: 'prospect_email' })
  email: string;

  @Expose({ name: 'pre_qualification_date_time' })
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  date: Date;

  @Expose({ name: 'employee_name' })
  evaluator: string;

  @Expose()
  getFullName() {
    const names: string[] = [];
    if (this.givenName) names.push(this.givenName);
    if (this.middledName) names.push(this.middledName);
    if (this.fathersLastName) names.push(this.fathersLastName);
    if (this.mothersLastName) names.push(this.mothersLastName);

    return names.join(' ');
  }

  @Expose()
  getShortDate() {
    const month = ('0' + (this.date.getMonth() + 1)).slice(-2);
    const date = ('0' + this.date.getDate()).slice(-2);
    const year = this.date.getFullYear();

    return date + '/' + month + '/' + year;
  }
}
