import { Expose } from 'class-transformer';

import {
  activityTypes,
  ACTIVITY_DEPENDENT,
  ACTIVITY_INDEPENDENT,
  ACTIVITY_MICRO_ENTREPRENEUR,
  ACTIVITY_OTROS,
  ACTIVITY_RENTIER,
  ACTIVITY_RETIRED,
  ACTIVITY_NO_ACTIVITY,
  noActivityTypes,
} from '../base';
import { IMaskPipe } from 'angular-imask';
import { penCurrencyAmount } from '../masks';

export class EconomicActivity {
  @Expose()
  uuid: string;

  @Expose()
  activityType: string;

  @Expose()
  data: any;

  @Expose()
  isPrincipalActivity: boolean;

  @Expose()
  getLabel() {
    if (this.activityType == ACTIVITY_NO_ACTIVITY) {
      return noActivityTypes[this.activityType];
    }

    let label = activityTypes[this.activityType];
    const penCurrencyAmountMask = new IMaskPipe();
    let income;
    if (this.data.income) {
      income = penCurrencyAmountMask.transform(this.data.income, penCurrencyAmount());
    }

    if (!income) income = 'S/ --';

    switch (this.activityType) {
      case ACTIVITY_DEPENDENT:
        label += ` - ${this.data.company} - ${income}`;
        break;
      case ACTIVITY_INDEPENDENT:
        label += ` - ${income}`;
        break;
      case ACTIVITY_MICRO_ENTREPRENEUR:
        label += ` - ${this.data.company} - ${income}`;
        break;
      case ACTIVITY_RETIRED:
        label += ` - ${income}`;
        break;
      case ACTIVITY_RENTIER:
        label += ` - ${income}`;
        break;
      case ACTIVITY_OTROS:
        label += ` - ${this.data.activityName} - ${income}`;
        break;
      default:
        break;
    }

    return label;
  }

  @Expose()
  getActivityResume() {
    return {
      uuid: this.uuid,
      type: this.activityType,
      data: this.data,
      isPrincipalActivity: this.isPrincipalActivity,
      label: this.getLabel(),
    };
  }
}
