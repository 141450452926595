import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { FinancialProduct } from '../../models';
import { DataService } from '../data.service';

@Injectable()
export class FinancialProductService {
  constructor(private dataService: DataService) {}

  getFinancialProducts(year: number) {
    return this.dataService
      .get<FinancialProduct[]>(`financial-products/get-all-parameters?year=${year}`)
      .pipe(map((res) => res.data));
  }

  getFinancialProductsByYears(year: number[]) {
    return this.dataService
      .get<Record<string, FinancialProduct[]>>(
        `financial-products/get-all-parameters-by-years?years=${year.join(',')}`,
      )
      .pipe(map((res) => res.data));
  }
}
