export class ConsolidatedPersonModel {
  documentType: string;
  documentNumber: string;
  fathersLastName: string;
  mothersLastName: string;
  marriedLastName: string;
  firstName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
}
