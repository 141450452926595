import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vv-textview',
  templateUrl: 'textview.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextviewComponent),
      multi: true,
    },
  ],
})
export class TextviewComponent extends BaseComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() value: string;
  @Input() labelCss: string;
  @Input() textCss: string;
  @Input() currencyTransform?: {
    code: string;
  };

  constructor(private currencyPipe: CurrencyPipe) {
    super();
  }

  get displayedValue() {
    if (this.currencyTransform) {
      const value = this.value;
      return this.currencyPipe.transform(value, this.currencyTransform.code);
    } else {
      return this.value;
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback: any = () => {
    // do nothing.
  };

  onTouchedCallback: any = () => {
    // do nothing.
  };
}
