export interface Tier {
  property_value_min: number;
  property_value_max: number;
  amount: number;
  pbp_amount: number;
}

export interface Bond {
  type: string;
  name: string;
  key: string;
  tiers: Tier[];
}

export interface FinancialProduct {
  uuid: string;
  product_name: string;
  product_code: string;
  property_value_min: number;
  property_value_max: number;
  mortgage_value_min: number | null;
  mortgage_value_max: number | null;
  min_own_contribution_percent: number;
  min_years: number;
  max_years: number;
  bonds: Bond[] | null;
  active: boolean;
}
