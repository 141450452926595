import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vv-chip',
  templateUrl: 'chip.component.html',
})
export class ChipComponent extends BaseComponent {
  @Input() item: { key: string; value: string };
  @Input() selected: boolean;
  @Input() disable = false;
  @Output() selectedChange = new EventEmitter<string>();

  constructor() {
    super();
  }

  onClick() {
    if (this.disable) {
      return;
    }

    this.selected = !this.selected;
    this.selectedChange.emit(this.item.key);
  }
}
