import { Injectable, NgZone } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { APP_STORAGE } from '@vivela/xplat/core';
import { SessionManager } from '@vivela/xplat/utils';
import { Store } from '@ngxs/store';
import { UpdateReturnUrl } from '@vivela/xplat/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private zone: NgZone, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const idToken = SessionManager.getItem(APP_STORAGE.ID_TOKEN);
    if (!idToken) {
      this.zone.run(() => {
        this.store
          .dispatch(
            new UpdateReturnUrl(
              this.router.getCurrentNavigation()?.extractedUrl.toString() || '',
            ),
          )
          .subscribe((a) => {
            this.router.navigate(['/login']);
          });
      });
    }

    const groups = this.decodeJwtData(idToken || '')['cognito:groups'];
    if (!groups) {
      this.zone.run(() => this.router.navigate(['/auth-error']));
    }

    return !!idToken;
  }

  private decodeJwtData(jwt: string) {
    const jwtData = jwt.split('.')[1];
    if (jwtData.length > 0) {
      const decodedJwtJsonData = window.atob(jwtData);
      return JSON.parse(decodedJwtJsonData);
    } else {
      return JSON.parse('{}');
    }
  }
}
