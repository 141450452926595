import { Expose } from 'class-transformer';

export class Simulation {
  @Expose()
  uuid: string;

  @Expose()
  projectCode: string;

  @Expose()
  paymentDay: number;

  @Expose()
  statementDeliveryModality?: string;

  @Expose()
  financialProduct: {
    code: string;
  };

  @Expose()
  attributes: any;

  @Expose()
  date?: Date;
}
