import { BreakpointObserver } from '@angular/cdk/layout';
import { Portal } from '@angular/cdk/portal';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

import { AuthService, BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vivela-sidebar',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent extends BaseComponent implements AfterViewInit {
  @Input() menu;
  @Input() userInfo: {
    name: string;
    nameInitials: string;
    email: string;
  };

  showMenu = true;

  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((res) => {
      this.showMenu = res.matches;
    });
  }

  onClickItem(item) {
    if (item.subMenu) {
      item.subMenuVisible = !item.subMenuVisible;
    }

    if (window.innerWidth < 768) {
      this.showMenu = false;
    }
  }

  onClickLogout() {
    this.authService.logout();
  }

  onClickMenu() {
    this.showMenu = !this.showMenu;
  }
}
