import { Injectable } from '@angular/core';
import { Project, SubProductResponse, Rate, EvaluationType } from '../../models';

import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { plainToClass, plainToInstance } from 'class-transformer';
import { ClientFoundModel } from '../../models/mi-casita/client-found.model';

@Injectable()
export class MiCasitaService {
  constructor(private dataService: DataService) {}

  getProjects(modality?: string) {
    let url = `mi-casita/projects`;

    if (modality) {
      url = `${url}?modality=${modality}`;
    }

    return this.dataService.get<Project[]>(url).pipe(map((res) => res.data));
  }

  getProject(projectCode: string) {
    return this.dataService
      .get<Project>(`mi-casita/projects/${projectCode}`)
      .pipe(map((res) => plainToClass(Project, res.data)));
  }

  getSubProducts() {
    return this.dataService
      .get<SubProductResponse>(`mi-casita/products/sub-products`)
      .pipe(map((res) => res.data));
  }

  getEvaluationType(product: string) {
    return this.dataService
      .get<EvaluationType[]>(`mi-casita/evaluation-type/${product}`)
      .pipe(map((res) => res.data));
  }

  calculateRate(params: {
    productCode: string;
    projectCode: string;
    economicActivityCode: string;
    subEconomicActivityCode: string;
    creditAmount: number;
    propertyValue: number;
    creditTerm: number;
    customerAge: number;
    initialPercentage: number;
  }) {
    return this.dataService
      .post<Rate>(`mi-casita/rates/calculate`, params)
      .pipe(map((res) => res.data));
  }

  searchClientByName(params: {
    preNames: string;
    fatherLastName: string;
    motherLastName: string;
  }) {
    return this.dataService
      .post<ClientFoundModel[]>(`mi-casita/client/search-by-name`, params)
      .pipe(map((res) => plainToInstance(ClientFoundModel, res.data)));
  }
}
