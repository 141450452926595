export enum Role {
  Admin = 'Admin',
  Auditor = 'Auditor',
  Consejeros = 'Consejeros',
  Consejero_Digital = 'Consejero_Digital',
  Supervisor_Comercial = 'Supervisor_Comercial',
  Gerente_Comercial = 'Gerente_Comercial',
  Gestor_de_Usuarios = 'Gestor_de_Usuarios',
  Supervisor_de_Usuarios = 'Supervisor_de_Usuarios',
  Promotores = 'Promotores',
  Visualizacion_Expediente_Crediticio = 'Visualizacion_Expediente_Crediticio',
  Analista_Legal = 'Analista_Legal',
}
