import { Component, Input } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

@Component({
  selector: 'vv-badge',
  templateUrl: 'badge.component.html',
})
export class BadgeComponent extends BaseComponent {
  @Input() color: string;
  @Input() text: string;
  @Input() size: string;
  @Input() titleCase: boolean;
  constructor() {
    super();
  }
}
