import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { finalize } from 'rxjs/operators';
import { ApplicationFileService } from '@vivela/xplat/api';

type StateModel = {
  uploadPreSignedUrlIsLoading: boolean;
};

const startUploadPreSignedLoader: Partial<StateModel> = {
  uploadPreSignedUrlIsLoading: true,
};
const stopUploadPreSignedLoader: Partial<StateModel> = {
  uploadPreSignedUrlIsLoading: false,
};

@StateRepository()
@State<StateModel>({
  name: 'ApplicationFile',
})
@Injectable()
export class ApplicationFileState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isUploadPreSignedUrlLoading() {
    return this.snapshot.uploadPreSignedUrlIsLoading;
  }

  constructor(private applicationFileService: ApplicationFileService) {
    super();
  }

  uploadPreSignedUrl(applicationUuid: string, fileName: string, fileType: string) {
    this.patchState(startUploadPreSignedLoader);
    return this.applicationFileService
      .uploadPreSignedUrl(applicationUuid, fileName, fileType)
      .pipe(
        finalize(() => {
          this.patchState(stopUploadPreSignedLoader);
        }),
      );
  }

  save(applicationUuid: string, s3ObjectKey: string) {
    return this.applicationFileService.save(applicationUuid, s3ObjectKey);
  }
}
