import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { Promotor } from '../../models';
import { DataService } from '../data.service';

@Injectable()
export class PromotersService {
  constructor(private dataService: DataService) {}

  getPromoters() {
    return this.dataService
      .get<Promotor[]>(`users/promotor`)
      .pipe(map((res) => plainToClass(Promotor, res.data)));
  }

  createPromoter(payload: Promotor) {
    return this.dataService.post('users/promotor', payload).pipe(map((res) => res.data));
  }
}
