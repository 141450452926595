<div class="badge badge-{{ color }} badge--{{ size }}">
  <div class="badge__circle"></div>
  <div class="badge__text ml-10">
    <div *ngIf="titleCase; then titleCaseText; else plainText"></div>
    <ng-template #titleCaseText>
      {{ text | titlecase }}
    </ng-template>
    <ng-template #plainText>
      {{ text }}
    </ng-template>
  </div>
</div>
