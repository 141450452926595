import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChip, MatChipList } from '@angular/material/chips';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

import { ChipSelectOption } from '../shared/models';

@UntilDestroy()
@Component({
  selector: 'mat-chips-select',
  templateUrl: './chips-select.component.html',
  styleUrls: ['../../dynamic-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MatChipsSelectComponent,
      multi: true,
    },
  ],
})
export class MatChipsSelectComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @ViewChild(MatChipList)
  chipList!: MatChipList;

  @Input() options: ChipSelectOption[] = [];

  value: string = '';

  onChange!: (value: string) => void;
  onTouch: any;

  disabled = false;

  constructor() {}

  writeValue(value: string): void {
    // When form value set when chips list initialized
    if (this.chipList && value) {
      this.selectChip(value);
    } else if (value) {
      // When chips not initialized
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {}

  async ngAfterViewInit() {
    // To avoid ExpressionChangedAfterItHasBeenCheckedError https://stackoverflow.com/a/68806251/4937858
    await Promise.resolve();

    // Suscribe to any select event on every chip
    this.chipList.chipSelectionChanges
      .pipe(
        untilDestroyed(this),
        map((event) => event.source),
      )
      .subscribe((chip) => {
        // Just propagate the new chip selected
        if (chip.selected) {
          this.value = chip.value;

          this.propagateChange(this.value);
        }
      });

    this.selectChip(this.value);
  }

  propagateChange(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  selectChip(value: string) {
    // For visual purposes, select and deselect the rest, these methods emit events
    this.chipList.chips.forEach((chipOption) => {
      if (chipOption.value == value) {
        chipOption.selected = true;
      } else {
        chipOption.selected = false;
      }
    });
  }

  toggleSelection(chip: MatChip) {
    if (!this.disabled) {
      this.selectChip(chip.value);
    }
  }
}
