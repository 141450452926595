import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_STORAGE } from '../base';
import { environment } from '@vivela/xplat/environment';
import { SessionManager } from '@vivela/xplat/utils';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private router: Router) {}

  get authUrl() {
    return `${environment.authBaseUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${environment.authCallbackUrl}&response_type=TOKEN&client_id=${environment.googleAuthClientId}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  }
  get accessToken() {
    return SessionManager.getItem(APP_STORAGE.ACCESS_TOKEN) || '';
  }

  get idToken() {
    return SessionManager.getItem(APP_STORAGE.ID_TOKEN) || '';
  }

  setUserCredentials(accessToken = '', idToken = '') {
    SessionManager.setItem(APP_STORAGE.ACCESS_TOKEN, accessToken);
    SessionManager.setItem(APP_STORAGE.ID_TOKEN, idToken);
  }

  getUserRoles() {
    const decodedJwtData = this.decodeJwtData(this.idToken);
    const groups: string[] = decodedJwtData['cognito:groups'];

    return groups;
  }

  userIsAdminOrGerenteComercial() {
    const userRoles = this.getUserRoles();

    return userRoles.includes('Admin') || userRoles.includes('Gerente_Comercial');
  }

  getUserName() {
    const decodedJwtData = this.decodeJwtData(this.idToken);

    const firstGivenName = decodedJwtData.given_name.split(' ')[0];
    const firstFamilyName = decodedJwtData.family_name.split(' ')[0];

    return `${firstGivenName} ${firstFamilyName}`;
  }

  getNameInitials() {
    const userName = this.getUserName();

    return userName
      .split(' ')
      .map((n) => n[0])
      .join('');
  }

  getUserMail() {
    const decodedJwtData = this.decodeJwtData(this.idToken);

    return decodedJwtData.email;
  }

  logout(navigateTo?: string) {
    // remove data
    SessionManager.clear();

    const route = navigateTo ? navigateTo : '/login';

    this.router.navigate([route]);
  }

  private decodeJwtData(jwt: string) {
    const jwtData = jwt.split('.')[1];
    if (jwtData.length > 0) {
      const decodedJwtJsonData = this.decodeBase64(jwtData);
      return JSON.parse(decodedJwtJsonData);
    } else {
      return JSON.parse('{}');
    }
  }

  private decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
  }
}
