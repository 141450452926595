import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  Inject,
  Provider,
} from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// libs
import { TranslateService } from '@ngx-translate/core';
import { throwIfAlreadyLoaded } from '@vivela/xplat/utils';
import { StoreModule } from '@vivela/xplat/store';
// app
import { environment } from '@vivela/xplat/environment';

import {
  CORE_PROVIDERS,
  JwtInterceptor,
  LogService,
  PlatformLanguageToken,
} from './services';

/**
 * DEBUGGING
 */
LogService.DEBUG.LEVEL_4 = environment.isDevelopment();

export const BASE_PROVIDERS: Provider[] = [
  ...CORE_PROVIDERS,
  {
    provide: APP_BASE_HREF,
    useValue: '/',
  },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];

@NgModule({
  imports: [
    CommonModule, //
    StoreModule.forRoot({ environment }),
  ],
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(configuredProviders: Provider[]): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...BASE_PROVIDERS, ...configuredProviders],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    @Inject(PlatformLanguageToken) lang: string,
    translate: TranslateService,
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');

    // ensure default platform language is set
    translate.use(lang);
    translate.setDefaultLang('es');
  }
}
