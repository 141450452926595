import { FeatureFlag } from '../../models/feature-flag.model';
import { Observable } from 'rxjs';

export class BaseFeatureFlagProvider {
  static priority = 0;

  static getDefaultValueForFirebase(feature: FeatureFlag) {
    return {
      key: feature.key,
      default: feature.defaultValue,
    };
  }

  static isFeatureEnabled(feature: FeatureFlag): Observable<boolean> {
    return new Observable((observer) => {
      observer.next(feature.defaultValue);
    });
  }

  static hasFeature(feature: FeatureFlag): boolean {
    return !!feature;
  }
}
