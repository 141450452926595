import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { SimulationService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  isLoading: boolean;
  data: {
    statementDeliveryModality: string;
  };
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'simulation',
  defaults: {
    isLoading: false,
    data: {
      statementDeliveryModality: '',
    },
  },
})
@Injectable()
export class SimulationState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  @Computed()
  get statementDeliveryModality() {
    return this.snapshot.data.statementDeliveryModality;
  }

  constructor(private simulationService: SimulationService) {
    super();
  }

  createSimulation(payload: any) {
    this.patchState(startLoader);

    return this.simulationService.createSimulation(payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  updateSimulation(uuid: string, payload: any) {
    this.patchState(startLoader);

    return this.simulationService.updateSimulation(uuid, payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  calculateSimulation(payload: { simulation: string }) {
    this.patchState(startLoader);

    return this.simulationService.calculateSimulation(payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  calculateNotarialFees(params: {
    projectCode: string;
    productCode: string;
    propertyValue: number;
    parkingLot: number;
    store: number;
    totalDownPayment: number;
    modality: number;
  }) {
    this.patchState(startLoader);

    return this.simulationService.calculateNotarialFees(params).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
