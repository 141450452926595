import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { AuthService, Role } from '@vivela/xplat/core';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private routesPerRole = {
    Admin: ['*'],
    Gerente_Comercial: [
      'prequalifications',
      'prequalifications/create',
      'prequalifications/import',
      'prequalifications/:id',
      'simulator',
      'reporting/pre-qualifications',
      'reporting/applications',
      'applications',
      'application/:id',
    ],
    Consejeros: [
      'prequalifications',
      'prequalifications/create',
      'prequalifications/import',
      'prequalifications/:id',
      'simulator',
      'presimulator',
      'reporting/pre-qualifications',
      'reporting/applications',
      'applications',
      'application/:id',
    ],
    Consejero_Digital: [
      'prequalifications',
      'prequalifications/create',
      'prequalifications/import',
      'prequalifications/:id',
      'simulator',
      'presimulator',
      'reporting/pre-qualifications',
      'applications',
      'application/:id',
    ],
    Promotores: [
      'prequalifications',
      'prequalifications/create',
      'prequalifications/:id',
      'simulator',
      'presimulator',
    ],
    Gestor_de_Usuarios: [
      'users',
      'users/:id',
      'users/create',
      'promoters',
      'promoters/create',
    ],
    Supervisor_de_Usuarios: ['users', 'users/:id', 'promoters'],
    Auditor: ['dashboard'],
    [Role.Analista_Legal]: ['legal', 'legal/:letterUuid'],
  };

  constructor(private zone: NgZone, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const currentRoutePath = route.routeConfig?.path;
    if (!currentRoutePath) return true;

    const currentUserRoles = this.authService.getUserRoles();

    let routes: string[] = [];

    for (const currentUserRole of currentUserRoles) {
      const routesPerRoleElement = this.routesPerRole[currentUserRole];
      if (Array.isArray(routesPerRoleElement)) {
        routes = routes.concat(routesPerRoleElement);
      }
    }

    if (routes.includes('*')) {
      return true;
    }

    return routes.includes(currentRoutePath);
  }
}
