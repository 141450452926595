<div class="skeleton">
  <h1 *ngIf="title" class="heading-4 mb-20">
    <span>{{ title }}</span>
  </h1>
  <div class="card">
    <div class="row">
      <div *ngFor="let formField of formFields" [class]="formField.cssClass">
        <div class="form-field"></div>
      </div>
    </div>
  </div>
</div>
