import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { LogsService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  isLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'logs',
})
@Injectable()
export class LogsState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  constructor(private logsService: LogsService) {
    super();
  }

  getUsersStats(days: number) {
    this.patchState(startLoader);

    return this.logsService.getUsersStats(days).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getRolesStats(days: number) {
    this.patchState(startLoader);

    return this.logsService.getRolesStats(days).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getUsersActions(days: number) {
    this.patchState(startLoader);

    return this.logsService.getUsersActions(days).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getRolesActions(days: number) {
    this.patchState(startLoader);

    return this.logsService.getRolesActions(days).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
