/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

declare const google: any;
declare const AWS: any;

export type GoogleAuthResult = {
  clientId: string;
  credential: string;
};

export type AWSCredentials = {
  accessKeyId: string;
  expireTime: Date;
  sessionToken: string;
};

@Injectable()
export class GoogleLoginProvider {
  static readonly PROVIDER_ID: string = 'GOOGLE';
  private authListener = new Subject<AWSCredentials>();

  initialize() {
    console.log('initialiazing goole signIn...');
    try {
      google.accounts.id.initialize({
        client_id:
          '912426073451-g5m8oo0k49259sq0g3bk59a99vthjslc.apps.googleusercontent.com',
        callback: this.loginHandler.bind(this),
      });
      google.accounts.id.prompt();
      google.accounts.id.renderButton(document.getElementById('googleSignInButton'), {
        theme: 'outline',
        size: 'large',
      });
      AWS.config.update({ region: 'us-east-1' });
    } catch (error) {
      console.log('error', error);
      this.authListener.error('error');
    }

    return this.authListener;
  }

  protected loginHandler(data: GoogleAuthResult) {
    // Add the Google access token to the Amazon Cognito credentials login map.
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:89760900-01ab-4c5c-b98a-6517e81bdbde',
      Logins: {
        'accounts.google.com': data.credential,
      },
    }) as AWSCredentials;

    // Obtain AWS credentials
    AWS.config.credentials.get(() => {
      console.log('Credentials ready');
      this.authListener.next(AWS.config.credentials);
    });
  }
}
