import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class UpdateReturnUrl {
  static readonly type = '[Auth] Update Return Url';
  constructor(public updateReturnUrl: string) {}
}

export class ClearReturnUrl {
  static readonly type = '[Auth] Clear Return Url';
}

type AuthStateModel = {
  returnUrl: string;
};

@State<AuthStateModel>({
  name: 'Auth',
  defaults: {
    returnUrl: '',
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static returnUrl(state: AuthStateModel): string {
    return state.returnUrl || '';
  }

  @Action(UpdateReturnUrl)
  updateReturnUrl(
    ctx: StateContext<AuthStateModel>,
    { updateReturnUrl }: UpdateReturnUrl,
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      returnUrl: updateReturnUrl,
    });
  }

  @Action(ClearReturnUrl)
  clearReturnUrl(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      returnUrl: '',
    });
  }
}
