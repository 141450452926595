import { asPartialIEnvironment, Env, IEnvironment } from '../environment.interface';
import { environmentBase } from './environment.base';

export const environmentStagLocal: IEnvironment = {
  ...environmentBase,
  ...asPartialIEnvironment({
    environment: Env.STG,
    baseUrl: 'http://localhost:3000',
    authBaseUrl: 'https://auth.backoffice.stg.vivela.io',
    googleAuthClientId: '466svgumm5ru9ghmh8v2k46kvn',
    authCallbackUrl: 'http://localhost:4200/callback',
    recaptchaSiteKey: '6Lf8Ln0hAAAAAIyfpLAYUehtKnQZ-r-SwyV_-7g2',
  }),
};
