import { DocumentType } from './';

export interface PrequalificationReportPerson {
  customer: { uuid: string };
  documentNumber: string;
  documentType: DocumentType;
  givenName: string;
  middleName?: string;
  fathersLastName: string;
  mothersLastName: string;
  mobilePhoneNumber: string;
  email: string;
  workClassification: { name: string };
  maritalStatus: string;
  declaredIncome: number;
  paymentCapacity: number;
  spouse: PrequalificationReportPerson;
}
