import { Component, Input } from '@angular/core';

@Component({
  selector: 'vv-upload-file',
  templateUrl: 'upload-file.html',
})
export class UploadFile {
  @Input() label: string;
  @Input() detail: string;

  uploadIcon: string | ArrayBuffer | null = 'assets/upload-outline.svg';

  constructor() {}
}
