import { PreQualificationState } from './pre-qualification.state';
import { UserState } from './user.state';
import { UsersState } from './users.state';
import { LogsState } from './logs.state';
import { SimulationState } from './simulation.state';
import { PresimulationState } from './presimulation.state';
import { CustomerState } from './customer.state';
import { ApplicationState } from './application.state';
import { FinancialProductState } from './financial-product.state';
import { PromotersState } from './promoters.state';
import { ProspectingState } from './prospecting.state';
import { MiCasitaState } from './mi-casita.state';
import { AuthState } from './auth.state';
import { SpouseShareInfoState } from './spouse-share-info.state';
import { S3PreSignedState } from './s3-pre-signed.state';
import { ApplicationFileState } from './application-file.state';
import { LetterState } from './letter.state';

export const CORE_STATES = [
  UserState,
  PreQualificationState,
  UsersState,
  LogsState,
  SimulationState,
  PresimulationState,
  CustomerState,
  ApplicationState,
  FinancialProductState,
  PromotersState,
  ProspectingState,
  MiCasitaState,
  AuthState,
  SpouseShareInfoState,
  S3PreSignedState,
  ApplicationFileState,
  LetterState,
];

export * from './user.state';
export * from './pre-qualification.state';
export * from './users.state';
export * from './logs.state';
export * from './simulation.state';
export * from './presimulation.state';
export * from './customer.state';
export * from './application.state';
export * from './financial-product.state';
export * from './promoters.state';
export * from './prospecting.state';
export * from './mi-casita.state';

export * from './spouse-share-info.state';

export * from './s3-pre-signed.state';
export * from './application-file.state';

//State with SessionStorage
export * from './auth.state';

export * from './letter.state';
