<div class="file">
  <div class="photo {{ labelCss }}">{{ label }}</div>
  <div class="upload-file">
    <input
      hidden
      type="file"
      (change)="onFileSelected($event)"
      #fileInput
      [accept]="accept"
    />
    <div *ngIf="valid && previewSrc; else changeImage">
      <div *ngIf="isNotExcel; else excelFile">
        <img [src]="previewSrc" (click)="fileInput.click()" />
      </div>
      <ng-template #excelFile>
        <span>{{ value.name }}</span>
      </ng-template>
    </div>
    <ng-template #changeImage>
      <vv-upload-file
        [label]="fileLabel"
        [detail]="fileDetail"
        (click)="fileInput.click()"
      ></vv-upload-file>
    </ng-template>
  </div>
</div>
