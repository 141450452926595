import { NgModule } from '@angular/core';

// xplat
import {
  UIModule,
  DynamicFormFieldModule,
  ErrorDialogModule,
} from '@vivela/xplat/web/features';
import { SHARED_COMPONENTS } from './components';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { IMaskModule, IMaskPipe } from 'angular-imask';

const MODULES = [
  UIModule,
  DynamicFormFieldModule,
  ErrorDialogModule,
  DirectivesModule,
  IMaskModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...SHARED_COMPONENTS],
  exports: [...MODULES, ...SHARED_COMPONENTS],
  providers: [IMaskPipe],
})
export class SharedModule {}
