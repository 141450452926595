import { Expose } from 'class-transformer';

export type SubProductResponse = {
  MV: SubProduct[];
  TP: SubProduct[];
  VH: SubProduct[];
};

export class SubProduct {
  @Expose()
  productCode: string;

  @Expose()
  subProductCode: string;

  @Expose()
  description: string;

  @Expose()
  mtoMin: number;

  @Expose()
  mtoMax: number;

  @Expose()
  inmValMin: number;

  @Expose()
  inmValMax: number;

  @Expose()
  paramMin: number;

  @Expose()
  paramMax: number;
}
