import { Expose, plainToInstance, Transform } from 'class-transformer';
import { LetterSingleModel } from '@vivela/xplat/api';

export class LetterListModel {
  @Expose()
  cursor: {
    previousCursor: string;
    nextCursor: string;
  };

  @Transform(({ value }) => plainToInstance(LetterSingleModel, value), {
    toClassOnly: true,
  })
  items: LetterSingleModel[];
}
