export type VivelaIconName =
  | 'list'
  | 'boda'
  | 'document'
  | 'email'
  | 'user'
  | 'carnet_de_identidad'
  | 'phone'
  | 'plus'
  | 'calendar'
  | 'payment_method'
  | 'check_list'
  | 'close'
  | 'discount'
  | 'arrow_down'
  | 'arrow_left'
  | 'arrow_right';
