import { Expose } from 'class-transformer';
import * as moment from 'moment';

export class GovernmentEmailModel {
  @Expose()
  uuid: string;

  @Expose()
  email: string;

  @Expose()
  createdAt: Date;

  @Expose()
  getShortCreatedAt() {
    return moment(this.createdAt).format('DD/MM/YYYY, hh:mm A');
  }
}
