import { Role } from './role.model';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  access_token?: string;
  id_token?: string;
}
