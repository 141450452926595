import { Expose } from 'class-transformer';

export class UserAction {
  @Expose()
  event: string;

  @Expose()
  timestamp: number;

  @Expose()
  affectedResource: number;

  @Expose()
  responsibleUser: number;
}
