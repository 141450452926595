import { Expose } from 'class-transformer';

export class Promotor {
  @Expose()
  fullName: string;

  @Expose()
  givenName: string;

  @Expose()
  middleName: string;

  @Expose()
  fathersLastName: string;

  @Expose()
  mothersLastName: string;

  @Expose()
  email: string;
}
