import { asPartialIEnvironment, Env, IEnvironment } from '../environment.interface';
import { environmentBase } from './environment.base';

export const environmentStag: IEnvironment = {
  ...environmentBase,
  ...asPartialIEnvironment({
    environment: Env.STG,
    baseUrl: 'https://api.backoffice.stg.vivela.io/backoffice',
    authBaseUrl: 'https://auth.backoffice.stg.vivela.io',
    googleAuthClientId: '7d2tsaogj25i13btg3khjvgfut',
    authCallbackUrl: 'https://backoffice.stg.vivela.io/callback',
    recaptchaSiteKey: '6LeT_H8hAAAAACA5TIrf8ozVQkJqaKyH3vrJo8-h',
  }),
};
