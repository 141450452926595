import { Expose } from 'class-transformer';

export class Parameter {
  @Expose()
  codGrp: string;

  @Expose()
  codIte: string;

  @Expose()
  descri: string;

  @Expose()
  situac: number;
}
