import { Expose, plainToInstance, Transform } from 'class-transformer';
import * as moment from 'moment/moment';
import { LetterCustomerModel } from './letter-customer.model';

export class LetterModel {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  status: string;

  @Expose()
  // @Type(() => Date)
  createdAt: Date;

  @Transform(({ value }) => plainToInstance(LetterCustomerModel, value), {
    toClassOnly: true,
  })
  letterCustomers: LetterCustomerModel[];

  @Expose()
  getShortCreatedAt() {
    return moment(this.createdAt).format('DD/MM/YYYY, hh:mm A');
  }

  @Expose()
  isBlacklisted() {
    return this.status === 'BLACKLISTED';
  }

  @Expose()
  isBlacklisting() {
    return this.status === 'BLACKLISTING';
  }

  @Expose()
  isSent() {
    return this.status === 'SENT';
  }
}
