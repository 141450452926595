import { Expose, Type } from 'class-transformer';
import * as moment from 'moment';

export class LetterSingleModel {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  status: string;

  @Expose()
  employeeFullName: string;

  @Expose()
  // @Type(() => Date)
  createdAt: Date;

  @Expose()
  getShortCreatedAt() {
    return moment(this.createdAt).format('DD/MM/YYYY, hh:mm A');
  }
}
