<div class="chip-box">
  <vv-chip
    *ngFor="let item of items | keyvalue"
    [item]="item"
    [selected]="values[item.key]"
    [disable]="disable"
    (selectedChange)="onChipSelected($event)"
  >
  </vv-chip>
</div>
