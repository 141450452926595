import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { PresimulatorService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  isLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'presimulation',
})
@Injectable()
export class PresimulationState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  constructor(private presimulationService: PresimulatorService) {
    super();
  }

  createPresimulation(payload: any) {
    this.patchState(startLoader);

    return this.presimulationService.createPresimulation(payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
