import { DataService } from '../data.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Prospecting } from '../../models';

@Injectable()
export class ProspectingService {
  constructor(private dataService: DataService) {}

  getByUuid(uuid: string) {
    return this.dataService
      .get<Prospecting>(`prospecting/prospecting/${uuid}`)
      .pipe(map((res) => res.data));
  }

  updateProspect(uuid: string, payload) {
    return this.dataService
      .patch(`prospecting/prospects/${uuid}`, payload)
      .pipe(map((res) => res.data));
  }

  invalidate(uuid: string) {
    return this.dataService
      .delete(`prospecting/prospecting/${uuid}`)
      .pipe(map((res) => res.data));
  }
}
