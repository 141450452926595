<div class="paginator">
  <div
    class="paginator__prev"
    [class]="{ disable: currentPage == 1 }"
    (click)="onClickPrev()"
  >
    <vv-icon icon="arrow_left" size="sm"></vv-icon>
  </div>

  <div
    *ngFor="let page of paginatorPages"
    class="paginator__page"
    [class]="{ active: page == currentPage }"
    (click)="onClickPage(page)"
  >
    {{ page }}
  </div>

  <div
    class="paginator__next"
    [class]="{ disable: currentPage == maxPages }"
    (click)="onClickNext()"
  >
    <vv-icon icon="arrow_right" size="sm"></vv-icon>
  </div>
</div>
