import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog.component';

@Injectable()
export class ErrorDialogService {
  private isDialogOpen = false;
  constructor(public dialog: MatDialog) {}
  openDialog(data: { title: string; content: string }): any {
    if (this.isDialogOpen) {
      return false;
    }
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      width: '560px',
      height: '360px',
      data: data,
      disableClose: true,
      panelClass: 'vivela-error-dialog-panel',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.isDialogOpen = false;
    });
  }
}
