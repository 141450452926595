import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { PromotersService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  isLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'promoters',
})
@Injectable()
export class PromotersState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  constructor(private promoterService: PromotersService) {
    super();
  }

  getPromoters() {
    this.patchState(startLoader);

    return this.promoterService.getPromoters().pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  createPromoter(payload: any) {
    this.patchState(startLoader);

    return this.promoterService.createPromoter(payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
