<div class="modal__close">
  <button mat-icon-button color="light" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal__body">
  <div class="error">
    <i class="material-icons">warning_amber</i>
    <div class="title">{{ data.title }}</div>
    <div class="message">{{ data.content }}</div>
    <button class="btn btn-primary" (click)="closeDialog()">Volver</button>
  </div>
</div>
