export enum PrequalificationPreFilterResultCode {
  Prequalified = 0,
  HaveACredit,
  InNegativeBase,
  InEvaluation,
  Evaluated,
}

export interface PrequalificationPreFilterResultDetails {
  bank?: string;
  date?: string;
  identifier?: string;
}

export interface PrequalificationPreFilterResult {
  code: PrequalificationPreFilterResultCode;
  details: PrequalificationPreFilterResultDetails | null;
}
