import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Person } from '../models/person.model';

@Injectable({ providedIn: 'any' })
export class VivelaBeService {
  constructor(private http: HttpClient) {}

  qualification(person: Person) {
    return this.http.post('https://xpnig6bsva.execute-api.us-west-2.amazonaws.com/call', {
      name: `${person.name} ${person.lastname}`,
      dni: person.dni,
    });
  }
}
