import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { S3PreSignedService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';

type StateModel = {
  uploadIsLoading: boolean;
};

const startUploadLoader: Partial<StateModel> = { uploadIsLoading: true };
const stopUploadLoader: Partial<StateModel> = { uploadIsLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'S3PreSigned',
})
@Injectable()
export class S3PreSignedState extends NgxsDataRepository<StateModel> {
  constructor(private s3PreSignedService: S3PreSignedService) {
    super();
  }

  uploadObject(signedUrl: string, file: File) {
    this.patchState(startUploadLoader);
    return this.s3PreSignedService.uploadObject(signedUrl, file).pipe(
      finalize(() => {
        this.patchState(stopUploadLoader);
      }),
    );
  }
}
