import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { map } from 'rxjs/operators';
import { User, Session, Promotor, RecoverPasswordModel } from '../../models';
import { DataService } from '../data.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UsersService {
  constructor(private dataService: DataService) {}

  getUsers() {
    return this.dataService
      .get<User[]>(`users`)
      .pipe(map((res) => plainToClass(User, res.data)));
  }

  getUserById(uuid: string) {
    return this.dataService
      .get<User>(`users/${uuid}`)
      .pipe(map((res) => plainToClass(User, res.data)));
  }

  getUserByWorkEmail(email: string) {
    return this.dataService
      .get<User>(`users/get-by-email`, {
        params: {
          email,
        },
      })
      .pipe(map((res) => plainToClass(User, res.data)));
  }

  createUser(payload: User) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this.dataService
      .post(`users`, payload, { headers })
      .pipe(map((res) => res.data));
  }

  updateUser(uuid: string, payload: User) {
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });
    return this.dataService
      .patch(`users/${uuid}`, payload, { headers })
      .pipe(map((res) => plainToClass(User, res.data)));
  }

  getUserRoles() {
    return this.dataService.get('roles').pipe(map((res) => res.data));
  }

  loginWithEmailAndPassword(email: string, password: string, recaptchaToken: string) {
    return this.dataService
      .post<Session>('users/auth/login', { email, password, recaptchaToken })
      .pipe(map((res) => res.data));
  }

  setNewPassword(
    email: string,
    password: string,
    rePassword: string,
    recaptchaToken: string,
  ) {
    return this.dataService
      .post<Session>('users/auth/set-new-password', {
        email,
        password,
        rePassword,
        recaptchaToken,
      })
      .pipe(map((res) => res.data));
  }

  forgotPassword(email: string, recaptchaToken: string) {
    return this.dataService
      .post<RecoverPasswordModel>('users/auth/forgot-password', { email, recaptchaToken })
      .pipe(map((res) => res.data));
  }

  resetPassword(
    email: string,
    password: string,
    confirmationCode: string,
    recaptchaToken: string,
  ) {
    return this.dataService
      .post<RecoverPasswordModel>('users/auth/reset-password', {
        email,
        password,
        confirmationCode,
        recaptchaToken,
      })
      .pipe(map((res) => res.data));
  }
}
