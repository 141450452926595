import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { PrequalificationListReport, PreQualificationService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type PaginationCursor = {
  type: 'previous' | 'next';
  value: string;
  pageIndex: number;
};

type StateModel = {
  isLoading: boolean;
  reports: PrequalificationListReport[];
  lastCursor: PaginationCursor;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'pre_qualifications',
})
@Injectable()
export class PreQualificationState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  @Computed()
  get reports() {
    return this.snapshot.reports;
  }

  constructor(private preQualificationService: PreQualificationService) {
    super();
  }

  getReports() {
    this.patchState(startLoader);

    return this.preQualificationService.getPrequalificationReports().pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getPreviousReports(cursor: string) {
    this.patchState(startLoader);

    return this.preQualificationService.getPreviousPrequalificationReports(cursor).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getNextReports(cursor: string) {
    this.patchState(startLoader);

    return this.preQualificationService.getNextPrequalificationReports(cursor).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getReportsByDocumentNumber(documentNumber: string) {
    this.patchState(startLoader);

    return this.preQualificationService
      .getPrequalificationReportsByDocumentNumber(documentNumber)
      .pipe(
        finalize(() => {
          this.patchState(stopLoader);
        }),
      );
  }

  getReport(id: string) {
    this.patchState(startLoader);

    return this.preQualificationService.getPrequalificationReportById(id).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getSimulationId(id: string) {
    this.patchState(startLoader);

    return this.preQualificationService.getSimulationIdByPrequalificationId(id).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getSimulationConfig() {
    this.patchState(startLoader);

    return this.preQualificationService.getSimulationConfig().pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getReportByProspecting(uuid: string) {
    this.patchState(startLoader);

    return this.preQualificationService.getPrequalificationReportByProspecting(uuid).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  forceUpdateReport(uuid: string) {
    this.patchState(startLoader);

    return this.preQualificationService.updatePrequalificationReport(uuid).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
