// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards';
import { AuthErrorComponent, OutOfTimeComponent } from './features/shared/components';

// app
import { SharedModule } from './features/shared/shared.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'credit/:applicationUuid/file/:fileUuid',
    loadChildren: () =>
      import('./features/credit-file/credit-file.module').then((m) => m.CreditFileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'recover-password',
    loadChildren: () =>
      import('./features/recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule,
      ),
  },
  {
    path: 'auth-error',
    component: AuthErrorComponent,
  },
  {
    path: 'out-of-time',
    component: OutOfTimeComponent,
  },
  {
    path: 'callback',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
})
export class AppRoutingModule {}
