export interface FeatureFlag {
  key: string;
  title: string;
  explanation: string;
  defaultValue: boolean;
}

export interface TestSetting {
  key: string;
  title: string;
  explanation: string;
  defaultValue: boolean;
}

export interface FeatureFlagValue {
  key: string;
  title: string;
  explanation: string;
  value: boolean;
}

export const FeatureFlagPriority = {
  MIN_PRIORITY: 100,
  MEDIUM_PRIORITY: 200,
  MAX_PRIORITY: 300,
  TEST_PRIORITY: 1000,
};

export const TestSettings = {
  DEBUG_FIREBASE: {
    key: 'debug_firebase',
    title: 'Debug firebase',
    explanation: 'Debug firebase in dev mode',
    defaultValue: true,
  },
};

export const FeatureFlags = {
  DARK_MODE: {
    key: 'dark_mode',
    title: 'Dark theme',
    explanation: 'Enabled dark mode',
    defaultValue: false,
  },
};
