import { PreQualificationService } from './pre-qualification';
import { UsersService } from './users';
import { LogsService } from './logs';
import { SimulationService } from './simulation';
import { PresimulatorService } from './presimulator';
import { CustomerService } from './customer';
import { ApplicationFileService, ApplicationService } from './application';
import { FinancialProductService } from './financial-product';
import { PromotersService } from './promoters';
import { ProspectingService } from './prospecting';
import { ApplicationsService, PreQualificationsService } from './reporting';
import { MiCasitaService } from './mi-casita';
import { S3PreSignedService } from './s3';
import { LetterService } from './legal';

export const API_PROVIDERS = [
  PreQualificationService,
  UsersService,
  LogsService,
  SimulationService,
  PresimulatorService,
  CustomerService,
  ApplicationService,
  FinancialProductService,
  PromotersService,
  ProspectingService,
  PreQualificationsService,
  ApplicationsService,
  MiCasitaService,
  S3PreSignedService,
  ApplicationFileService,
  LetterService,
];

export * from './pre-qualification';
export * from './users';
export * from './logs';
export * from './simulation';
export * from './presimulator';
export * from './customer';
export * from './application';
export * from './financial-product';
export * from './promoters';
export * from './prospecting';
export * from './reporting';
export * from './mi-casita';
export * from './s3';
export * from './legal';
