<mat-chip-list selectable multiple [disabled]="disabled">
  <mat-chip
    #c="matChip"
    *ngFor="let option of options"
    (click)="toggleSelection(c)"
    [value]="option.value"
    [disabled]="disabled"
  >
    {{ option.label }}
  </mat-chip>
</mat-chip-list>
