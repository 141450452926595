import { asPartialIEnvironment, Env, IEnvironment } from '../environment.interface';
import { environmentBase } from './environment.base';

export const environmentDev: IEnvironment = {
  ...environmentBase,
  ...asPartialIEnvironment({
    environment: Env.DEV,
    baseUrl: 'https://api.backoffice.dev.vivela.io/backoffice',
    authBaseUrl: 'https://auth.backoffice.dev.vivela.io',
    googleAuthClientId: '3s67n3vncldbi7dt0nndpinq3l',
    authCallbackUrl: 'https://backoffice.dev.vivela.io/callback',
    recaptchaSiteKey: '6Lf8Ln0hAAAAAIyfpLAYUehtKnQZ-r-SwyV_-7g2',
  }),
};
