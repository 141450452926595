<div class="sidebar">
  <div class="sidebar__brand">
    <img routerLink="/" src="assets/vivela-logo.svg" />
    <button mat-icon-button (click)="onClickMenu()">
      <mat-icon>{{ showMenu ? 'close' : 'menu' }}</mat-icon>
    </button>
  </div>
  <div *ngIf="showMenu" class="nav-list">
    <div class="sidebar__menu">
      <div *ngFor="let item of menu" class="sidebar__menu__section">
        <div
          *vivelaCheckRole="item.roles"
          class="sidebar__menu__item"
          [routerLink]="item.url"
          [routerLinkActive]="item.url ? 'active' : ''"
          (click)="onClickItem(item)"
        >
          <vv-icon [icon]="item.icon"></vv-icon>
          <div class="sidebar__menu__item__text">{{ item.text }}</div>
          <vv-icon
            class="ml-auto"
            *ngIf="item.subMenu"
            icon="arrow_down"
            size="sm"
          ></vv-icon>
        </div>
        <div *ngIf="item.subMenu && item.subMenuVisible" class="sidebar__submenu">
          <div *ngFor="let item of item.subMenu">
            <div
              class="sidebar__submenu__item"
              *vivelaCheckRole="item.roles"
              [routerLink]="item.url"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="onClickItem(item)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar__user">
      <div class="sidebar__user__avatar">
        <span class="sidebar__user__avatar__text">
          {{ userInfo.nameInitials }}
        </span>
      </div>
      <div class="sidebar__user__info">
        <div class="text-bold">{{ userInfo.name }}</div>
        <div>{{ userInfo.email }}</div>
      </div>
    </div>
    <div class="sidebar__logout">
      <button mat-stroked-button color="primary-200" (click)="onClickLogout()">
        <mat-icon>power_settings_new</mat-icon>
        Cerrar sesión
      </button>
    </div>
  </div>
</div>
