export const penCurrencyAmount = (options?: {
  min?: number;
  max?: number;
  scale?: number;
}) => {
  const scale = options?.scale === 0 ? 0 : options?.scale || 2;

  const customMask = {
    mask: [
      {
        mask: 'S/ num',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            signed: true,
            scale: scale,
            thousandsSeparator: ',',
            padFractionalZeros: true,
            radix: '.',
            mapToRadix: ['.'],
            ...(options?.min && { min: options.min }),
            ...(options?.max && { max: options.max }),
          },
        },
      },
    ],
  };

  return customMask;
};
