import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// libs
import { UISharedModule } from '@vivela/xplat/features';
import { UI_COMPONENTS } from './components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxLoadingButtonsModule } from 'ngx-loading-buttons';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  UISharedModule,
  FontAwesomeModule,
  MatProgressSpinnerModule,
  NgxLoadingButtonsModule,
  MatListModule,
  MatSnackBarModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...UI_COMPONENTS],
  exports: [...MODULES, ...UI_COMPONENTS],
})
export class UIModule {}
