export const amountMask = (options?: { min?: number; max?: number }) => {
  const customMask = {
    mask: [
      {
        mask: 'num',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            signed: true,
            scale: 2,
            thousandsSeparator: ',',
            padFractionalZeros: true,
            radix: '.',
            mapToRadix: ['.'],
            ...(options?.min && { min: options.min }),
            ...(options?.max && { max: options.max }),
          },
        },
      },
    ],
  };

  return customMask;
};
