import { Expose } from 'class-transformer';

export class Prospecting {
  @Expose()
  uuid: string;

  @Expose()
  source: string;

  @Expose()
  prospect: any;

  @Expose()
  preSimulation: any;

  @Expose()
  simulation: any;

  @Expose()
  totalObligations: number;

  @Expose()
  debtCapacityRatios: {
    MV: {
      value: number;
    };
    TP: {
      value: number;
    };
    VH: {
      value: number;
      ratioPerDebt?: {
        maxDebt: number;
        value: number;
      };
    };
  };
}
