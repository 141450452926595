import { PrequalificationReportDocument } from './';

export interface PrequalificationReportPerson {
  principalBorrower: boolean;
  givenName: string;
  middleName?: string;
  fathersLastName: string;
  mothersLastName: string;
  documents: PrequalificationReportDocument[];
  mobilePhoneNumber: string;
  email: string;
  workClassification: string;
  maritalStatus: string;
  declaredIncome: number;
  paymentCapacity: number;
}
