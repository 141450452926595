import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { UsersService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

type StateModel = {
  isLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'users',
})
@Injectable()
export class UsersState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  constructor(private userService: UsersService) {
    super();
  }

  getUsers() {
    this.patchState(startLoader);

    return this.userService.getUsers().pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getUserById(id: string) {
    this.patchState(startLoader);

    return this.userService.getUserById(id).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getUserByWorkEmail(email: string) {
    this.patchState(startLoader);

    return this.userService.getUserByWorkEmail(email).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  createUser(payload: any) {
    this.patchState(startLoader);

    return this.userService.createUser(payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  updateUser(uuid: string, payload: any) {
    this.patchState(startLoader);

    return this.userService.updateUser(uuid, payload).pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }

  getUserRoles(): Observable<any> {
    this.patchState(startLoader);

    return this.userService.getUserRoles().pipe(
      finalize(() => {
        this.patchState(stopLoader);
      }),
    );
  }
}
