import { Expose } from 'class-transformer';

export class LetterCustomerModel {
  @Expose()
  uuid: string;

  @Expose()
  documentType: number | null;

  @Expose()
  documentNumber: string | null;

  @Expose()
  preNames: string | null;

  @Expose()
  fatherLastName: string | null;

  @Expose()
  motherLastName: string | null;

  product: any;

  @Expose()
  getFullName() {
    const names: string[] = [];
    if (this.preNames) names.push(this.preNames);
    if (this.fatherLastName) names.push(this.fatherLastName);
    if (this.motherLastName) names.push(this.motherLastName);

    return names.join(' ');
  }

  @Expose()
  getDocumentTypeLabel() {
    switch (this.documentType) {
      case 1:
        return 'DNI';
      case 2:
        return 'CE';
      case 3:
        return 'RUC';
      default:
        return '';
    }
  }

  @Expose()
  hasProduct() {
    return !!this.product;
  }
}
