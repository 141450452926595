<div class="bg-primary-200 vh-100 d-flex flex-column justify-content-center">
  <div class="heading-1 text-primary-0 text-center mb-30">Fuera del horario</div>
  <div class="heading-4 text-white text-bold text-center mb-50">
    Recuerda ingresar de 8:00 am a 9:00 pm
  </div>
  <div class="text-center">
    <img src="assets/out-of-time.png" />
  </div>
  <div class="mt-4 d-flex justify-content-center">
    <button mat-raised-button color="primary-200" routerLink="/">Volver al inicio</button>
  </div>
</div>
