import { Expose } from 'class-transformer';

export class ClientFoundModel {
  @Expose()
  documentType: number;

  @Expose()
  documentNumber: string;

  @Expose()
  preNames: string;

  @Expose()
  fatherLastName: string;

  @Expose()
  motherLastName: string;

  @Expose()
  getFullName() {
    const names: string[] = [];
    if (this.preNames) names.push(this.preNames);
    if (this.fatherLastName) names.push(this.fatherLastName);
    if (this.motherLastName) names.push(this.motherLastName);

    return names.join(' ');
  }

  @Expose()
  getDocumentTypeLabel() {
    switch (this.documentType) {
      case 1:
        return 'DNI';
      case 2:
        return 'CE';
      case 3:
        return 'RUC';
      default:
        return '';
    }
  }
}
