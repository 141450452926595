import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { finalize } from 'rxjs/operators';
import { MiCasitaService } from '@vivela/xplat/api';

type StateModel = {
  isLoading: boolean;
  clientSearchIsLoading: boolean;
};

const startLoader: Partial<StateModel> = { isLoading: true };
const stopLoader: Partial<StateModel> = { isLoading: false };

const startClientSearchLoader: Partial<StateModel> = { clientSearchIsLoading: true };
const stopClientSearchLoader: Partial<StateModel> = { clientSearchIsLoading: false };

@StateRepository()
@State<StateModel>({
  name: 'micasita',
})
@Injectable()
export class MiCasitaState extends NgxsDataRepository<StateModel> {
  @Computed()
  get isLoading() {
    return this.snapshot.isLoading;
  }

  @Computed()
  get clientSearchIsLoading() {
    return this.snapshot.clientSearchIsLoading;
  }

  constructor(private miCasitaService: MiCasitaService) {
    super();
  }

  getProjects(modality?: string) {
    return this.miCasitaService.getProjects(modality).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  getProject(projectCode: string) {
    return this.miCasitaService.getProject(projectCode).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  getSubProducts() {
    return this.miCasitaService.getSubProducts().pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  getEvaluationType(product: string) {
    return this.miCasitaService.getEvaluationType(product).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  calculateRate(params: {
    productCode: string;
    projectCode: string;
    economicActivityCode: string;
    subEconomicActivityCode: string;
    creditAmount: number;
    propertyValue: number;
    creditTerm: number;
    customerAge: number;
    initialPercentage: number;
  }) {
    return this.miCasitaService.calculateRate(params).pipe(
      finalize(() => {
        //Todo: add loader
      }),
    );
  }

  searchClientByName(params: {
    preNames: string;
    fatherLastName: string;
    motherLastName: string;
  }) {
    this.patchState(startClientSearchLoader);
    return this.miCasitaService.searchClientByName(params).pipe(
      finalize(() => {
        this.patchState(stopClientSearchLoader);
      }),
    );
  }
}
