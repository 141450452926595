<div *ngIf="formField?.extLabel" class="mat-ext-label">{{ formField.extLabel }}</div>
<ng-container
  [formGroup]="form"
  *ngIf="!formField.hidden"
  [ngSwitch]="formField.controlType"
>
  <!-- TextField -->
  <mat-form-field
    *ngSwitchCase="'textfield'"
    [className]="formField.readonly ? 'mat-form-field-readonly' : ''"
  >
    <mat-label>{{ formField.label }}</mat-label>
    <input
      [type]="
        formField.type !== 'password'
          ? formField.type
          : hidePassword
          ? 'password'
          : 'text'
      "
      [autocomplete]="formField.type === 'password' ? 'current-password' : null"
      [formControlName]="formField.key"
      [min]="formField.min"
      [step]="formField.step"
      [readonly]="formField.readonly"
      [imask]="formField.mask"
      [unmask]="formField.unmask"
      (keydown.enter)="onKeydownEnter ? onKeydownEnter() : null"
      matInput
    />
    <div
      *ngIf="formField.type === 'password'"
      class="text-secondary-200 cursor-pointer"
      (click)="hidePassword = !hidePassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide"
      mat-icon-button
      matSuffix
    >
      <i class="material-icons-outlined">{{
        hidePassword ? 'visibility_off' : 'visibility'
      }}</i>
    </div>
    <mat-error *ngIf="!isValid">
      <span *ngIf="formControl.errors?.required">Completa este campo</span>
      <span *ngIf="formControl.errors?.minlength"
        >Ingresa {{ formControl.errors.minlength.requiredLength }} caracteres como
        mínimo</span
      >
      <span *ngIf="formControl.errors?.maxlength"
        >Ingresa {{ formControl.errors.maxlength.requiredLength }} caracteres como
        máximo</span
      >
      <span *ngIf="formControl.errors?.documentsRepeated">Documentos repetidos</span>
      <span *ngIf="formControl.errors?.atLeastOneRequired"
        >Completar al menos un campo</span
      >
      <span *ngIf="formControl.errors?.invalidDocument">Documento inválido</span>
      <span *ngIf="formControl.errors?.invalidRUC">RUC inválido</span>
      <span *ngIf="formControl.errors?.invalidName">Ingresa un nombre válido</span>
      <span *ngIf="formControl.errors?.invalidSurname">Ingresa un apellido válido</span>
      <span *ngIf="formControl.errors?.email">Ingresa un correo válido</span>
      <span *ngIf="formControl.errors?.invalidVivelaEmail"
        >Ingresa un correo vívela válido</span
      >
      <span *ngIf="formControl.errors?.invalidNumber"> Ingresa un número válido </span>
      <span *ngIf="formControl.errors?.invalidNumeric">
        Debes ingresar solo números
      </span>
      <span *ngIf="formControl.errors?.invalidMobilePhone">
        Ingresa un número de celular válido
      </span>
      <span *ngIf="formControl.errors?.invalidPhone">
        Ingresa un número de teléfono válido
      </span>
      <span *ngIf="formControl.errors?.invalidIncome">
        El ingreso debe ser entre 1,000 y 50,000 soles
      </span>
      <span *ngIf="formControl.errors?.propertyMinValue">
        El valor mínimo del inmueble debe ser de:
        {{ formControl.errors.propertyMinValue.propertyMinValue }}
      </span>
      <span *ngIf="formControl.errors?.propertyMaxValue">
        <ng-container
          *ngIf="formControl.errors.propertyMaxValue.propertyMaxValue > 0; else isZero"
        >
          El valor máximo del inmueble debe ser de:
          {{ formControl.errors.propertyMaxValue.propertyMaxValue }}
        </ng-container>
        <ng-template #isZero>
          El subproducto está mal configurado, notifíquelo a un administrador.
        </ng-template>
      </span>
      <span *ngIf="formControl.errors?.mortgageMinValue">
        El valor mínimo del crédito debe ser de:
        {{ formControl.errors.mortgageMinValue.mortgageMinValue }}
      </span>
      <span *ngIf="formControl.errors?.mortgageMaxValue">
        El valor máximo del crédito debe ser de:
        {{ formControl.errors.mortgageMaxValue.mortgageMaxValue }}
      </span>
      <span *ngIf="formControl.errors?.ownContributionMinValue">
        La cuota inicial mínima debe ser de:
        {{ formControl.errors.ownContributionMinValue.ownContributionMinValue }}
      </span>
      <span *ngIf="formControl.errors?.invalidPassword">
        Debe incluir números, mayúsculas y un carácter especial
      </span>
      <span *ngIf="formControl.errors?.shortPassword">
        La contraseña debe tener al menos 8 caracteres
      </span>
      <span *ngIf="formControl.errors?.invalideRepassword">
        Las contraseñas no coinciden
      </span>
    </mat-error>
    <mat-hint>
      <span *ngIf="formField.hint"></span>
    </mat-hint>
  </mat-form-field>

  <!-- Autocomplete -->
  <mat-form-field *ngSwitchCase="'autocomplete'">
    <mat-label>{{ formField.label }}</mat-label>
    <input
      #autocompleteInput
      [formControl]="autocompleteControl"
      [matAutocomplete]="auto"
      [type]="formField.type"
      [readonly]="formField.readonly"
      matInput
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="setFormControlValue($event.option.value)"
      (closed)="updateFormControlValueAfterClosePanel()"
    >
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
    <span class="material-icons"> arrow_drop_down </span>
    <mat-error *ngIf="!isValid">
      <span *ngIf="autocompleteControl.errors?.required">Completa este campo</span>
    </mat-error>
  </mat-form-field>

  <!-- DatePicker -->
  <mat-form-field *ngSwitchCase="'datepicker'">
    <mat-label>{{ formField.label }}</mat-label>
    <input
      #datepickerInput
      vivelaMaskDate
      [formControlName]="formField.key"
      [min]="formField.minDate"
      [max]="formField.maxDate"
      [matDatepicker]="picker"
      matInput
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker [startAt]="formField.startDate"></mat-datepicker>
    <mat-error *ngIf="!isValid">
      <div *ngIf="formControl.errors?.required">Ingresa una fecha válida</div>
      <div *ngIf="formControl.errors?.matDatepickerMax">
        {{
          formField.messages?.matDatepickerMax || 'La fecha supera el limite permitido'
        }}
      </div>
      <div *ngIf="formControl.errors?.matDatepickerMin">
        {{ formField.messages?.matDatepickerMin || 'La fecha es menor de lo permitido' }}
      </div>
    </mat-error>
  </mat-form-field>

  <!-- TextView -->
  <ng-container *ngSwitchCase="'textview'">
    <vv-textview
      [formControlName]="formField.key"
      [label]="formField.label"
      [labelCss]="formField.labelCss"
      [textCss]="formField.textCss"
      [currencyTransform]="formField.currencyTransform"
    ></vv-textview>
  </ng-container>

  <!-- Switch -->
  <ng-container *ngSwitchCase="'switch'">
    <mat-slide-toggle [formControlName]="formField.key" color="primary">{{
      formField.label
    }}</mat-slide-toggle>
  </ng-container>

  <!-- CheckBox -->
  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox [formControlName]="formField.key" color="primary">{{
      formField.label
    }}</mat-checkbox>
  </ng-container>

  <!-- Radio Button -->
  <ng-container *ngSwitchCase="'radio-button'">
    <div class="vv-radio-button row">
      <div
        *ngIf="formField.label"
        class="vv-radio-button-label"
        [class]="formField.labelCss"
        [innerHTML]="formField.label | safe: 'html'"
      ></div>
      <mat-radio-group
        aria-label="{{ formField.label }}"
        [formControlName]="formField.key"
        [ngClass]="{ 'radio-button-invalid': formControl.touched && formControl.invalid }"
      >
        <mat-radio-button
          color="primary"
          value="{{ option.value }}"
          *ngFor="let option of formField.options"
          >{{ option.label }}</mat-radio-button
        >
      </mat-radio-group>
      <span *ngIf="formControl.touched && formControl.invalid" class="mat-error"
        >Selecciona una opción</span
      >
      <div
        class="subtitle"
        *ngIf="
          formField.dynamicSubTittle &&
          formField.dynamicSubTittle.value.includes(formControl.value)
        "
      >
        {{ formField.dynamicSubTittle.text }}
      </div>
    </div>
  </ng-container>

  <!-- ChipsMultiSelect -->
  <ng-container *ngSwitchCase="'chips-multi-select'">
    <div class="body-2 text-secondary-200 mb-20 tooltip {{ formField?.labelCss }}">
      {{ formField.label }}
      <span *ngIf="formField.tooltip" class="tooltip-text">{{ formField.tooltip }}</span>
    </div>
    <mat-chips-multi-select
      [formControlName]="formField.key"
      [options]="formField.options"
    >
    </mat-chips-multi-select>
    <mat-error *ngIf="!isValid">
      <div *ngIf="formControl.errors?.required">Seleciona una o más opciones</div>
    </mat-error>
  </ng-container>

  <!-- Chips -->
  <ng-container *ngSwitchCase="'chips-select'">
    <div class="body-2 text-secondary-200 mb-20 {{ formField?.labelCss }}">
      {{ formField.label }}
    </div>
    <mat-chips-select [formControlName]="formField.key" [options]="formField.options">
    </mat-chips-select>
    <mat-error *ngIf="!isValid">
      <div *ngIf="formControl.errors?.required">Seleciona una o más opciones</div>
    </mat-error>
  </ng-container>

  <!-- Chips Autocomplete -->
  <mat-form-field
    *ngSwitchCase="'chips-autocomplete'"
    class="chips-autocomplete"
    appearance="outline"
  >
    <mat-label>{{ formField.label }}</mat-label>
    <mat-chip-list #chipList multiple [formControlName]="formField.key">
      <ng-container *ngIf="formField.options.length > 0">
        <mat-chip
          *ngFor="let value of getFormControlValue()"
          [removable]="!formField.disabled"
          [value]="value"
          (removed)="removeFormControlValue(value)"
        >
          {{ getLabelByValue(value) }}
          <button matChipRemove *ngIf="!formField.disabled">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </ng-container>
      <input
        #autocompleteInput
        [formControl]="autocompleteControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        (click)="autocompleteClicked(formField)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="addFormControlValue($event.option.value)"
    >
      <mat-option *ngIf="formField.isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!formField.isLoading && formField.options.length > 0">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <mat-hint *ngIf="formField.loadOptionsFail">
      <div>Falló la consulta de roles, por favor vuelva a intentarlo</div>
    </mat-hint>
  </mat-form-field>

  <!-- Select -->
  <mat-form-field *ngSwitchCase="'select'">
    <mat-label>{{ formField.label }}</mat-label>
    <mat-select [formControlName]="formField.key">
      <mat-option *ngFor="let item of formField.options" [value]="item.value">
        {{ item.label }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="!isValid">
      <div *ngIf="formControl.errors?.required">Completa este campo</div>
      <div *ngIf="formControl.errors?.documentsRepeated">Documentos repetidos</div>
      <div *ngIf="formControl.errors?.invalidDocument">Documento inválido</div>
    </mat-error>
  </mat-form-field>

  <!-- Slider-->
  <ng-container *ngSwitchCase="'slider'">
    <div class="body-2 text-secondary-200 mb-20">{{ formField.label }}</div>
    <div>
      <mat-slider
        color="primary"
        [formControlName]="formField.key"
        thumbLabel
        [displayWith]="formatLabel"
        tickInterval="1000"
        step="formField.step"
        [min]="formField.min"
        [max]="formField.max"
        aria-label="units"
      >
      </mat-slider>
    </div>
    <span class="mat-slider-min">{{ formField.min + ' ' + formField.unit }}</span>
    <span class="mat-slider-max">{{ formField.max + ' ' + formField.unit }}</span>
  </ng-container>

  <!-- File -->
  <ng-container *ngSwitchCase="'file'">
    <vv-file
      [formControlName]="formField.key"
      [label]="formField.label"
      [fileLabel]="formField.fileLabel"
      [labelCss]="formField.labelCss"
      [previewSrc]="formField.previewSrc"
      [valid]="!formControl.errors"
      [accept]="formField.accept"
    ></vv-file>
    <mat-error>
      <span *ngIf="formControl.errors?.invalidSizePhoto">
        El tamaño de la foto debe ser menor o igual a 500KB
      </span>
      <span *ngIf="formControl.errors?.invalidTypePhoto">
        Debes subir una imagen con extensión jpg, png o jpeg
      </span>
      <span *ngIf="formControl.errors?.invalidTypeExcel">
        Debes subir un archivo con extensión xlsx
      </span>
      <span *ngIf="formControl.errors?.invalidSizeExcel">
        El tamaño del archivo debe ser menor o igual a 800KB
      </span>
    </mat-error>
  </ng-container>
</ng-container>
