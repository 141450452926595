<div class="select input-placeholder">
  <select
    class="select__btn width-100"
    [class]="{ invalid: validate && name.invalid, notEmpty: optionSelected }"
    [ngModel]="optionSelected"
    (ngModelChange)="onChange($event)"
    #name="ngModel"
    required
    [disabled]="disable"
    (blur)="onTouched()"
  >
    <option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </option>
  </select>
  <label>{{ label }}</label>
  <div class="select__icon">
    <vv-icon icon="arrow_down" size="sm"></vv-icon>
  </div>
</div>
