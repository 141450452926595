export interface ModalOptions {
  type?: ModalBodyTypes;
  icon?: string;
  title: string;
  message?: string;
  action?: {
    type: ModalActionTypes;
    text: string;
    route?: string;
  };
  button?: ModalButtonOptions;
}

export interface ModalButtonOptions {
  text: string;
  onClick?: boolean;
  routerLink?: string;
}

type ModalBodyTypes = 'success' | 'error';
type ModalActionTypes = 'close' | 'navigate';
