<div class="input-placeholder">
  <input
    [class]="{ invalid: validate && name.invalid, notEmpty: value }"
    [type]="type"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    #name="ngModel"
    required
    [disabled]="disable"
    [email]="type == 'email'"
    (blur)="onTouched()"
    [min]="min"
    [step]="step"
  />
  <label>{{ label }}</label>
</div>
