import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

import { EnvironmentConfig, ENV_CONFIG } from '../tokens';
import { RequestResponse } from '../base';

type RequestOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
};

@Injectable({ providedIn: 'root' })
export class DataService {
  apiUrl: string;

  constructor(
    @Inject(ENV_CONFIG) private config: EnvironmentConfig,
    private http: HttpClient,
  ) {
    this.apiUrl = `${config.environment.baseUrl}`;
  }

  get<T>(path: string, options?: RequestOptions): Observable<RequestResponse<T>> {
    return this.http.get<RequestResponse<T>>(`${this.apiUrl}/${path}`, options);
  }

  getBlob(path: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${path}`, {
      responseType: 'blob',
    });
  }

  post<T>(
    path: string,
    body: unknown,
    options?: RequestOptions,
  ): Observable<RequestResponse<T>> {
    return this.http.post<RequestResponse<T>>(`${this.apiUrl}/${path}`, body, options);
  }

  getDownloadable<T>(path: string, body: unknown): Observable<Blob> {
    return this.http.post(`${this.apiUrl}/${path}`, body, {
      responseType: 'blob',
    });
  }

  getTemp<T>(path: string, options?: RequestOptions): Observable<RequestResponse<T>> {
    const tempUrl = 'https://macwpc2kh4.us-east-1.awsapprunner.com';
    return this.http.get<RequestResponse<T>>(`${tempUrl}/${path}`, options);
  }

  postTemp<T>(
    path: string,
    body: unknown,
    options?: RequestOptions,
  ): Observable<RequestResponse<T>> {
    const tempUrl = 'https://macwpc2kh4.us-east-1.awsapprunner.com';
    return this.http.post<RequestResponse<T>>(`${tempUrl}/${path}`, body, options);
  }

  patch<T>(
    path: string,
    body: unknown,
    options?: RequestOptions,
  ): Observable<RequestResponse<T>> {
    return this.http.patch<RequestResponse<T>>(`${this.apiUrl}/${path}`, body, options);
  }

  put<T>(
    path: string,
    body?: unknown,
    options?: RequestOptions,
  ): Observable<RequestResponse<T>> {
    return this.http.put<RequestResponse<T>>(`${this.apiUrl}/${path}`, body, options);
  }

  delete<T>(path: string, options?: RequestOptions): Observable<RequestResponse<T>> {
    return this.http.delete<RequestResponse<T>>(`${this.apiUrl}/${path}`, options);
  }
}
