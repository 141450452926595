/* eslint-disable @typescript-eslint/no-explicit-any*/
import {
  Component,
  Directive,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SwitchBaseComponent } from '@vivela/xplat/features';

@Component({
  selector: 'vv-switch',
  templateUrl: 'switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent extends SwitchBaseComponent implements ControlValueAccessor {
  @Input() color = 'primary';
  @Input() label: string;
  @Input() fieldName: string;
  @Input() checked: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  disable: boolean;

  writeValue(obj: boolean): void {
    this.checked = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback: any = () => {
    // do nothing.
  };

  onTouchedCallback: any = () => {
    // do nothing.
  };

  onClick() {
    if (this.disable) {
      return;
    }

    this.checked = !this.checked;
    this.onChangeCallback(this.checked);
    this.checkedChange.emit(this.checked);
  }
}

@Directive({
  selector: 'vv-switch[disable]',
})
export class SwitchDisableDirective {
  constructor(@Host() @Self() @Optional() host: SwitchComponent) {
    host.disable = true;
  }
}
