import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { LetterService } from '@vivela/xplat/api';
import { finalize } from 'rxjs/operators';

type StateModel = {
  createIsLoading: boolean;
  listIsLoading: boolean;
  detailIsLoading: boolean;
  addCustomerIsLoading: boolean;
  removeCustomerIsLoading: boolean;
  sendCustomersToBlacklistIsLoading: boolean;
  dispatchIsLoading: boolean;
  saveLetterDispatchResponseIsLoading: boolean;
  downloadDispatchResponseFileIsLoading: boolean;
  dispatchResponseIsLoading: boolean;
};

const startCreateLoader: Partial<StateModel> = { createIsLoading: true };
const stopCreateLoader: Partial<StateModel> = { createIsLoading: false };

const startListLoader: Partial<StateModel> = { listIsLoading: true };
const stopListLoader: Partial<StateModel> = { listIsLoading: false };

const startDetailLoader: Partial<StateModel> = { detailIsLoading: true };
const stopDetailLoader: Partial<StateModel> = { detailIsLoading: false };

const startAddCustomerLoader: Partial<StateModel> = { addCustomerIsLoading: true };
const stopAddCustomerLoader: Partial<StateModel> = { addCustomerIsLoading: false };

const startRemoveCustomerLoader: Partial<StateModel> = { removeCustomerIsLoading: true };
const stopRemoveCustomerLoader: Partial<StateModel> = { removeCustomerIsLoading: false };

const startSendCustomersToBlacklistLoader: Partial<StateModel> = {
  sendCustomersToBlacklistIsLoading: true,
};
const stopSendCustomersToBlacklistLoader: Partial<StateModel> = {
  sendCustomersToBlacklistIsLoading: false,
};

const startDispatchLoader: Partial<StateModel> = { dispatchIsLoading: true };
const stopDispatchLoader: Partial<StateModel> = { dispatchIsLoading: false };

const startSaveLetterDispatchResponseLoader: Partial<StateModel> = {
  saveLetterDispatchResponseIsLoading: true,
};
const stopSaveLetterDispatchResponseLoader: Partial<StateModel> = {
  saveLetterDispatchResponseIsLoading: false,
};

const startDownloadDispatchResponseFileLoader: Partial<StateModel> = {
  downloadDispatchResponseFileIsLoading: true,
};
const stopDownloadDispatchResponseFileLoader: Partial<StateModel> = {
  downloadDispatchResponseFileIsLoading: false,
};

const startDispatchResponseLoader: Partial<StateModel> = {
  dispatchResponseIsLoading: true,
};
const stopDispatchResponseLoader: Partial<StateModel> = {
  dispatchResponseIsLoading: false,
};

@StateRepository()
@State<StateModel>({
  name: 'letter',
})
@Injectable()
export class LetterState extends NgxsDataRepository<StateModel> {
  @Computed()
  get createIsLoading() {
    return this.snapshot.createIsLoading;
  }

  @Computed()
  get listIsLoading() {
    return this.snapshot.listIsLoading;
  }

  @Computed()
  get detailIsLoading() {
    return this.snapshot.detailIsLoading;
  }

  @Computed()
  get addCustomerIsLoading() {
    return this.snapshot.addCustomerIsLoading;
  }

  @Computed()
  get removeCustomerIsLoading() {
    return this.snapshot.removeCustomerIsLoading;
  }

  @Computed()
  get sendCustomersToBlacklistIsLoading() {
    return this.snapshot.sendCustomersToBlacklistIsLoading;
  }

  @Computed()
  get dispatchIsLoading() {
    return this.snapshot.dispatchIsLoading;
  }

  @Computed()
  get saveLetterDispatchResponseIsLoading() {
    return this.snapshot.saveLetterDispatchResponseIsLoading;
  }

  @Computed()
  get downloadDispatchResponseFileIsLoading() {
    return this.snapshot.downloadDispatchResponseFileIsLoading;
  }

  @Computed()
  get dispatchResponseIsLoading() {
    return this.snapshot.dispatchResponseIsLoading;
  }

  constructor(private letterService: LetterService) {
    super();
  }

  list(previousCursor?: string, nextCursor?: string) {
    this.patchState(startListLoader);

    return this.letterService.list(previousCursor, nextCursor).pipe(
      finalize(() => {
        this.patchState(stopListLoader);
      }),
    );
  }

  create(name: string, confirm?: boolean) {
    this.patchState(startCreateLoader);

    return this.letterService.create(name, confirm).pipe(
      finalize(() => {
        this.patchState(stopCreateLoader);
      }),
    );
  }

  detail(letterUuid: string) {
    this.patchState(startDetailLoader);

    return this.letterService.detail(letterUuid).pipe(
      finalize(() => {
        this.patchState(stopDetailLoader);
      }),
    );
  }

  addCustomer(payload: any) {
    this.patchState(startAddCustomerLoader);

    return this.letterService.addCustomer(payload).pipe(
      finalize(() => {
        this.patchState(stopAddCustomerLoader);
      }),
    );
  }

  removeCustomer(payload: any) {
    this.patchState(startRemoveCustomerLoader);

    return this.letterService.removeCustomer(payload).pipe(
      finalize(() => {
        this.patchState(stopRemoveCustomerLoader);
      }),
    );
  }

  sendCustomersToBlacklist(letterUuid: string) {
    this.patchState(startSendCustomersToBlacklistLoader);

    return this.letterService.sendCustomersToBlacklist(letterUuid).pipe(
      finalize(() => {
        this.patchState(stopSendCustomersToBlacklistLoader);
      }),
    );
  }

  dispatchLetter(letterUuid: string, payload?: any) {
    this.patchState(startDispatchLoader);

    return this.letterService.dispatchLetter(letterUuid, payload).pipe(
      finalize(() => {
        this.patchState(stopDispatchLoader);
      }),
    );
  }

  dispatchResponseLetter(letterUuid: string, payload: { recipientEmail: any }) {
    this.patchState(startDispatchResponseLoader);

    return this.letterService.dispatchLetter(letterUuid, payload).pipe(
      finalize(() => {
        this.patchState(stopDispatchResponseLoader);
      }),
    );
  }

  saveLetterDispatchResponse(letterUuid: string, payload: { recipient: string }) {
    this.patchState(startSaveLetterDispatchResponseLoader);
    return this.letterService.saveLetterDispatchResponse(letterUuid, payload).pipe(
      finalize(() => {
        this.patchState(stopSaveLetterDispatchResponseLoader);
      }),
    );
  }

  downloadDispatchResponseFile(letterUuid: string) {
    this.patchState(startDownloadDispatchResponseFileLoader);

    return this.letterService.downloadDispatchResponseFile(letterUuid).pipe(
      finalize(() => {
        this.patchState(stopDownloadDispatchResponseFileLoader);
      }),
    );
  }

  getGovernmentEmails() {
    return this.letterService.getGovernmentEmails();
  }
}
