import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PreQualificationsService {
  constructor(private dataService: DataService) {}

  getByDates(from: Date, to: Date) {
    return this.dataService
      .getDownloadable(`reporting/pre-qualifications/by-dates`, { from, to })
      .pipe(map((blob) => blob));
  }
}
