import { NgModule } from '@angular/core';

import { GlobalHttpInterceptorService } from './services';

// xplat
import { VivelaCoreModule } from '@vivela/xplat/web/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [VivelaCoreModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
  ],
})
export class CoreModule {}
