import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DataService } from '../data.service';

@Injectable()
export class PresimulatorService {
  constructor(private dataService: DataService) {}

  createPresimulation(payload: any) {
    return this.dataService
      .post(`prospecting/pre-simulations`, payload)
      .pipe(map((res) => res.data));
  }
}
