import { Component, Input } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';
import { VivelaIconName } from './types';

@Component({
  selector: 'vv-icon',
  templateUrl: 'icon.component.html',
})
export class IconComponent extends BaseComponent {
  @Input() icon: VivelaIconName;
  @Input() size: string;

  constructor() {
    super();
  }
}
