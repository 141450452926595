import { Component, Directive, Host, Input, Optional, Self } from '@angular/core';

import { BaseComponent } from '@vivela/xplat/core';

import { ListItem } from './types';

@Component({
  selector: 'vv-list',
  templateUrl: 'list.component.html',
})
export class ListComponent extends BaseComponent {
  @Input() listItems: ListItem[];

  isListIcon: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    this.listItems.forEach((item) => {
      item.type = typeof item.itemValue == 'boolean' ? 'boolean' : 'string';
    });
  }
}

@Directive({
  selector: 'vv-list[listIcon]',
})
export class ListIconDirective {
  constructor(@Host() @Self() @Optional() host: ListComponent) {
    host.isListIcon = true;
  }
}
