import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { map } from 'rxjs/operators';
import { UserState, RoleState, UserAction } from '../../models';
import { DataService } from '../data.service';

@Injectable()
export class LogsService {
  constructor(private dataService: DataService) {}

  getUsersStats(days: number) {
    return this.dataService
      .get<UserState[]>(`audit/users-stats-report?days=${days}`)
      .pipe(map((res) => plainToClass(UserState, res.data)));
  }

  getRolesStats(days: number) {
    return this.dataService
      .get<RoleState[]>(`audit/roles-stats-report?days=${days}`)
      .pipe(map((res) => plainToClass(RoleState, res.data)));
  }

  getUsersActions(days: number) {
    return this.dataService
      .get<RoleState[]>(`audit/users-actions-report?days=${days}`)
      .pipe(map((res) => plainToClass(UserAction, res.data)));
  }

  getRolesActions(days: number) {
    return this.dataService
      .get<RoleState[]>(`audit/roles-actions-report?days=${days}`)
      .pipe(map((res) => plainToClass(UserAction, res.data)));
  }
}
