import { Expose } from 'class-transformer';

export class Project {
  @Expose()
  codigo: string;

  @Expose()
  titulo: string;

  @Expose()
  tipBie: number;

  @Expose()
  valAfeBV: number;

  @Expose()
  gasCieAsu: number;

  @Expose()
  pryMCS: number;

  @Expose()
  ubigeo:
    | {
        department: {
          code: string;
          name: string;
        };
        province: {
          code: string;
          name: string;
        };
        district: {
          code: string;
          name: string;
        };
      }
    | string;
}
